// eslint-disable-file import/order
import { createIcon } from './createIcon';

import { ReactComponent as Add } from 'assets/icons/add.svg';
import { ReactComponent as Alert } from 'assets/icons/alert.svg';
import { ReactComponent as ArrowDown } from 'assets/icons/arrow-down.svg';
import { ReactComponent as ArrowLeft } from 'assets/icons/arrow-left.svg';
import { ReactComponent as ArrowUp } from 'assets/icons/arrow-up.svg';
import { ReactComponent as BackArrow } from 'assets/icons/back-arrow.svg';
import { ReactComponent as CapturedSize } from 'assets/icons/captured-size.svg';
import { ReactComponent as Chevron } from 'assets/icons/chevron.svg';
import { ReactComponent as Cog } from 'assets/icons/cog.svg';
import { ReactComponent as Cross } from 'assets/icons/cross.svg';
import { ReactComponent as Expand } from 'assets/icons/expand.svg';
import { ReactComponent as Explore } from 'assets/icons/explore.svg';
import { ReactComponent as ExportTask } from 'assets/icons/export-tasks.svg';
import { ReactComponent as EyeClosed } from 'assets/icons/eye-closed.svg';
import { ReactComponent as Eye } from 'assets/icons/eye.svg';
import { ReactComponent as Feedback } from 'assets/icons/feedback.svg';
import { ReactComponent as FitContent } from 'assets/icons/fit-content.svg';
import { ReactComponent as Folder } from 'assets/icons/folder.svg';
import { ReactComponent as AlignCenter } from 'assets/icons/formatting/align-center.svg';
import { ReactComponent as AlignLeft } from 'assets/icons/formatting/align-left.svg';
import { ReactComponent as AlignRight } from 'assets/icons/formatting/align-right.svg';
import { ReactComponent as BulletList } from 'assets/icons/formatting/bullet-list.svg';
import { ReactComponent as DashList } from 'assets/icons/formatting/dash-list.svg';
import { ReactComponent as Kalam } from 'assets/icons/formatting/kalam.svg';
import { ReactComponent as MultipleStyles } from 'assets/icons/formatting/multiple-styles.svg';
import { ReactComponent as NoList } from 'assets/icons/formatting/no-list.svg';
import { ReactComponent as NotoSans } from 'assets/icons/formatting/noto-sans.svg';
import { ReactComponent as NumberList } from 'assets/icons/formatting/number-list.svg';
import { ReactComponent as PermanentMarker } from 'assets/icons/formatting/permanent-marker.svg';
import { ReactComponent as RobotoSlab } from 'assets/icons/formatting/roboto-slab.svg';
import { ReactComponent as Grid } from 'assets/icons/grid.svg';
import { ReactComponent as Group } from 'assets/icons/group.svg';
import { ReactComponent as Hand } from 'assets/icons/hand-tool.svg';
import { ReactComponent as Image } from 'assets/icons/image.svg';
import { ReactComponent as List } from 'assets/icons/list.svg';
import { ReactComponent as Minus } from 'assets/icons/minus.svg';
import { ReactComponent as MultipleSizes } from 'assets/icons/multiple-sizes.svg';
import { ReactComponent as OpenOutside } from 'assets/icons/open-outside.svg';
import { ReactComponent as Options } from 'assets/icons/options.svg';
import { ReactComponent as Planner } from 'assets/icons/planner.svg';
import { ReactComponent as Plus } from 'assets/icons/plus.svg';
import { ReactComponent as Pointer } from 'assets/icons/pointer.svg';
import { ReactComponent as PostitLogo } from 'assets/icons/postit-logo.svg';
import { ReactComponent as PresentTeams } from 'assets/icons/present-teams.svg';
import { ReactComponent as Question } from 'assets/icons/question.svg';
import { ReactComponent as RedoArrow } from 'assets/icons/redo-arrow.svg';
import { ReactComponent as SadFace } from 'assets/icons/sad-face.svg';
import { ReactComponent as Search } from 'assets/icons/search.svg';
import { ReactComponent as SecondaryFeatures } from 'assets/icons/secondary-features.svg';
import { ReactComponent as SortNotes } from 'assets/icons/smart-group.svg';
import { ReactComponent as Stack } from 'assets/icons/stack.svg';
import { ReactComponent as Subtract } from 'assets/icons/subtract.svg';
import { ReactComponent as Swirl } from 'assets/icons/swirl.svg';
import { ReactComponent as Thumbtack } from 'assets/icons/thumbtack.svg';
import { ReactComponent as TooltipQuestionMark } from 'assets/icons/tooltip-question-mark.svg';
import { ReactComponent as Voting } from 'assets/icons/trigger-voting.svg';
import { ReactComponent as UndoArrow } from 'assets/icons/undo-arrow.svg';
import { ReactComponent as Ungroup } from 'assets/icons/ungroup.svg';
import { ReactComponent as Unstack } from 'assets/icons/unstack.svg';
import { ReactComponent as UppercaseArrow } from 'assets/icons/uppercase-arrow.svg';

export const AddIcon = createIcon({ icon: Add, displayName: 'AddIcon' });
export const AlertIcon = createIcon({ icon: Alert, displayName: 'AlertIcon' });
export const AlignCenterIcon = createIcon({
  icon: AlignCenter,
  displayName: 'AlignCenterIcon'
});
export const AlignLeftIcon = createIcon({
  icon: AlignLeft,
  displayName: 'AlignLeftIcon'
});
export const AlignRightIcon = createIcon({
  icon: AlignRight,
  displayName: 'AlignRightIcon'
});
export const ArrowDownIcon = createIcon({
  icon: ArrowDown,
  displayName: 'ArrowDownIcon'
});
export const ArrowLeftIcon = createIcon({
  icon: ArrowLeft,
  displayName: 'ArrowLeftIcon'
});
export const ArrowUpIcon = createIcon({
  icon: ArrowUp,
  displayName: 'ArrowUpIcon'
});
export const BackArrowIcon = createIcon({
  icon: BackArrow,
  displayName: 'BackArrowIcon'
});
export const BulletListIcon = createIcon({
  icon: BulletList,
  displayName: 'BulletListIcon'
});
export const CapturedSizeIcon = createIcon({
  icon: CapturedSize,
  displayName: 'CapturedSizeIcon'
});
export const ChevronIcon = createIcon({
  icon: Chevron,
  displayName: 'ChevronIcon'
});
export const CrossIcon = createIcon({ icon: Cross, displayName: 'CrossIcon' });
export const CogIcon = createIcon({ icon: Cog, displayName: 'CogIcon' });
export const DashListIcon = createIcon({
  icon: DashList,
  displayName: 'DashListIcon'
});
export const EyeIcon = createIcon({ icon: Eye, displayName: 'EyeIcon' });
export const EyeClosedIcon = createIcon({
  icon: EyeClosed,
  displayName: 'EyeClosedIcon'
});
export const ExpandIcon = createIcon({
  icon: Expand,
  displayName: 'ExpandIcon'
});
export const ExploreIcon = createIcon({
  icon: Explore,
  displayName: 'ExploreIcon'
});
export const ExportTaskIcon = createIcon({
  icon: ExportTask,
  displayName: 'ExportTaskIcon'
});
export const FeedbackIcon = createIcon({
  icon: Feedback,
  displayName: 'FeedbackIcon'
});
export const FitContentIcon = createIcon({
  icon: FitContent,
  displayName: 'FitContentIcon'
});
export const FolderIcon = createIcon({
  icon: Folder,
  displayName: 'FolderIcon'
});
export const GridIcon = createIcon({ icon: Grid, displayName: 'GridIcon' });
export const GroupIcon = createIcon({ icon: Group, displayName: 'GroupIcon' });
export const HandIcon = createIcon({ icon: Hand, displayName: 'HandIcon' });
export const ImageIcon = createIcon({ icon: Image, displayName: 'ImageIcon' });
export const KalamIcon = createIcon({ icon: Kalam, displayName: 'KalamIcon' });
export const ListIcon = createIcon({ icon: List, displayName: 'ListIcon' });
export const MinusIcon = createIcon({ icon: Minus, displayName: 'MinusIcon' });
export const MultipleSizesIcon = createIcon({
  icon: MultipleSizes,
  displayName: 'MultipleSizesIcon'
});
export const MultipleStylesIcon = createIcon({
  icon: MultipleStyles,
  displayName: 'MultipleStylesIcon'
});
export const NoListIcon = createIcon({
  icon: NoList,
  displayName: 'NoListIcon'
});
export const NotoSansIcon = createIcon({
  icon: NotoSans,
  displayName: 'NotoSansIcon'
});
export const NumberListIcon = createIcon({
  icon: NumberList,
  displayName: 'NumberListIcon'
});
export const OpenOutsideIcon = createIcon({
  icon: OpenOutside,
  displayName: 'OpenOutsideIcon'
});
export const OptionsIcon = createIcon({
  icon: Options,
  displayName: 'OptionsIcon'
});
export const PermanentMarkerIcon = createIcon({
  icon: PermanentMarker,
  displayName: 'PermanentMarkerIcon'
});
export const PlannerIcon = createIcon({
  icon: Planner,
  displayName: 'PlannerIcon'
});
export const PlusIcon = createIcon({ icon: Plus, displayName: 'PlusIcon' });
export const PresentTeamsIcon = createIcon({
  icon: PresentTeams,
  displayName: 'PresentTeamsIcon'
});
export const PointerIcon = createIcon({
  icon: Pointer,
  displayName: 'PointerIcon'
});
export const PostitLogoIcon = createIcon({
  icon: PostitLogo,
  displayName: 'PostitLogoIcon',
  alt: 'Post-it logo'
});
export const QuestionIcon = createIcon({
  icon: Question,
  displayName: 'QuestionIcon'
});
export const RedoArrowIcon = createIcon({
  icon: RedoArrow,
  displayName: 'RedoArrowIcon'
});
export const RobotoSlabIcon = createIcon({
  icon: RobotoSlab,
  displayName: 'RobotoSlabIcon'
});
export const SadFaceIcon = createIcon({
  icon: SadFace,
  displayName: 'SadFaceIcon'
});
export const SearchIcon = createIcon({
  icon: Search,
  displayName: 'SearchIcon'
});
export const SecondaryFeaturesIcon = createIcon({
  icon: SecondaryFeatures,
  displayName: 'SecondaryFeaturesIcon'
});
export const SortNotesIcon = createIcon({
  icon: SortNotes,
  displayName: 'SortNotesIcon'
});
export const SubtractIcon = createIcon({
  icon: Subtract,
  displayName: 'SubtractIcon'
});
export const StackIcon = createIcon({ icon: Stack, displayName: 'StackIcon' });
export const SwirlIcon = createIcon({ icon: Swirl, displayName: 'SwirlIcon' });
export const ThumbtackIcon = createIcon({
  icon: Thumbtack,
  displayName: 'ThumbtackIcon'
});
export const TooltipQuestionMarkIcon = createIcon({
  icon: TooltipQuestionMark,
  displayName: 'TooltipQuestionMarkIcon'
});
export const UndoArrowIcon = createIcon({
  icon: UndoArrow,
  displayName: 'UndoArrowIcon'
});
export const UngroupIcon = createIcon({
  icon: Ungroup,
  displayName: 'UngroupIcon'
});
export const UnstackIcon = createIcon({
  icon: Unstack,
  displayName: 'UnstackIcon'
});
export const UppercaseArrowIcon = createIcon({
  icon: UppercaseArrow,
  displayName: 'UppercaseArrowIcon'
});
export const VotingIcon = createIcon({
  icon: Voting,
  displayName: 'VotingIcon'
});

export const Icons = {
  Add: AddIcon,
  Alert: AlertIcon,
  ArrowDown: ArrowDownIcon,
  ArrowLeft: ArrowLeftIcon,
  ArrowUp: ArrowUpIcon,
  BackArrow: BackArrowIcon,
  CapturedSize: CapturedSizeIcon,
  Chevron: ChevronIcon,
  Cog: CogIcon,
  Cross: CrossIcon,
  Expand: ExpandIcon,
  Explore: ExploreIcon,
  ExportTask: ExportTaskIcon,
  EyeClosed: EyeClosedIcon,
  Eye: EyeIcon,
  Feedback: FeedbackIcon,
  FitContent: FitContentIcon,
  Folder: FolderIcon,
  AlignCenter: AlignCenterIcon,
  AlignLeft: AlignLeftIcon,
  AlignRight: AlignRightIcon,
  BulletList: BulletListIcon,
  DashList: DashListIcon,
  Kalam: KalamIcon,
  MultipleStyles: MultipleStylesIcon,
  NoList: NoListIcon,
  NotoSans: NotoSansIcon,
  NumberList: NumberListIcon,
  PermanentMarker: PermanentMarkerIcon,
  RobotoSlab: RobotoSlabIcon,
  Grid: GridIcon,
  Group: GroupIcon,
  Hand: HandIcon,
  Image: ImageIcon,
  List: ListIcon,
  Minus: MinusIcon,
  MultipleSizes: MultipleSizesIcon,
  Options: OptionsIcon,
  Planner: PlannerIcon,
  Plus: PlusIcon,
  PresentTeams: PresentTeamsIcon,
  Pointer: PointerIcon,
  PostitLogo: PostitLogoIcon,
  Question: QuestionIcon,
  RedoArrow: RedoArrowIcon,
  SadFace: SadFaceIcon,
  Search: SearchIcon,
  SecondaryFeatures: SecondaryFeaturesIcon,
  SortNotes: SortNotesIcon,
  Stack: StackIcon,
  Subtract: SubtractIcon,
  Swirl: SwirlIcon,
  TooltipQuestionMark: TooltipQuestionMarkIcon,
  Thumbtack: ThumbtackIcon,
  UndoArrow: UndoArrowIcon,
  Ungroup: UngroupIcon,
  Unstack: UnstackIcon,
  UppercaseArrow: UppercaseArrowIcon,
  Voting: VotingIcon
};
