/* eslint-disable no-template-curly-in-string */

import { InferType, object, string, array, mixed } from 'yup';

export const TEAM_MEMBER_ROLES = {
  Member: 'member',
  Administrator: 'administrator'
} as const;
export const teamMemberRoleSchema = mixed<Values<typeof TEAM_MEMBER_ROLES>>()
  .test({
    name: 'teamMemberRole',
    message: '${path} must be a key of `TEAM_MEMBER_ROLES`',
    test: value =>
      string().oneOf(Object.values(TEAM_MEMBER_ROLES)).isValidSync(value)
  })
  .default(TEAM_MEMBER_ROLES.Member);
export type TeamRole = Values<typeof TEAM_MEMBER_ROLES>;

export const personNameSchema = object({
  first: string().required(),
  last: string().required()
}).required();
export type PersonName = InferType<typeof personNameSchema>;

export const teamMemberSchema = object({
  id: string().required(),
  name: personNameSchema,
  role: teamMemberRoleSchema
}).required();
export type TeamMember = InferType<typeof teamMemberSchema>;

export const teamSchema = object({
  id: string().required(),
  name: string()
    .required('A name is required')
    .max(50, 'It should be less then 50 chars'),
  members: array(teamMemberSchema).ensure(),
  createdAt: string()
}).required();
export type Team = InferType<typeof teamSchema>;
