import React from 'react';
import { Navigate } from 'react-router';

import { useGetTeamsContext } from '../../utils/teams';
import { StartConfiguredBoard } from './StartConfiguredBoard';

const Start = () => {
  const teamsContext = useGetTeamsContext();
  const sessionId = teamsContext?.meetingId || teamsContext?.channelId;

  const renderRedirectComponent = () => {
    const route = !sessionId
      ? 'boards'
      : teamsContext.frameContext === 'sidePanel' && 'side-panel';

    return route ? (
      <Navigate to={route} />
    ) : (
      <StartConfiguredBoard sessionId={sessionId!} />
    );
  };

  return <div>{teamsContext && renderRedirectComponent()}</div>;
};

// eslint-disable-next-line import/no-default-export
export default Start;
