import { curry } from 'lodash';
import lib from 'mmm-postit-teams-core/core';

import { NoteElement } from '../canvas/types';

import type {
  Group,
  Note,
  SizeClass,
  SizeClassObject
} from '../types/core.schema';

export const { common, core } = lib.com.mmm.postit.teams;

export const {
  setProperty,
  addMetadata,
  deleteMetadata,
  groupNotes,
  animations,
  forEachMapElement,
  setNoteText
} = core.js;
export const {
  NoteUuid: KNoteUuid,
  GroupUuid: KGroupUuid,
  UserUuid: KUserUuid,
  VoteUuid: KVoteUuid,
  MetadataUuid: KMetadataUuid,
  MillimeterSize: KMillimeterSize,
  parseUuid,
  randomUuid: getUuid,
  SizeClass: KSizeClass
} = common;
export const {
  Metadata,
  Point: KPoint,
  Rect: KRect,
  moveNote,
  moveGroup,
  deleteGroup,
  Vote: { Location: KLocation },
  addVote,
  deleteVote,
  clearAllVotesForUser,
  cursorMoved,
  viewportMoved,
  moveGroupToSuggestedPosition,
  moveNoteToSuggestedPosition,
  duplicateGroupWithId,
  duplicateNoteWithId,
  duplicateGroup,
  duplicateNote,
  resizeGroup,
  FractionalIndex,
  resizeNote,
  rotateNote,
  defaultSuggestedGroupName: suggestedGroupName,
  notesSorted,
  ResizeAnchor,
  GroupResizeNoteBehavior,
  MetadataProperty,
  UserProperties,
  Note: KNote,
  Group: KGroup,
  GroupWithNotes: KGroupWithNotes,
  setShowCursors,
  NotePlacement: KNotePlacement,
  GroupPlacement: KGroupPlacement,
  objectDragged,
  dragCanceled,
  text: coreText,
  NoteTextData,
  AnnotatedString,
  search,
  setSelection: transmitSelection,
  clearSelection: clearTransmittedSelection,
  select: KSelect,
  unselect: KUnselect,
  bringToFront,
  bringToRear,
  suggestedCenterForObject,
  enterZenMode,
  exitZenMode
} = core;

export const { MapWrapper, ListWrapper, SetWrapper } = common.collections;
class UndoListener extends core.UndoManager.Listener {
  constructor(
    undoState: (canUndo: boolean) => void,
    redoState: (canRedo: boolean) => void
  ) {
    super();
    this.onStateChanged = undoManager => {
      undoState(undoManager.canUndo);
      redoState(undoManager.canRedo);
    };
  }
}

export const setupCore = ({
  maxDriftMs = -1,
  boardId,
  clientId,
  listeners: { onCanUndo, onCanRedo, ...listeners }
}: {
  boardId: string;
  clientId: number;
  listeners: lib.com.mmm.postit.teams.core.js.CrdtCallback & {
    onCanUndo: (api: ClientApi, canUndo: boolean) => void;
    onCanRedo: (api: ClientApi, canRedo: boolean) => void;
  };
  maxDriftMs?: number;
}): ReturnType<typeof core.js.setupCore> => {
  const clientApi = core.js.setupCore(
    boardId,
    clientId,
    listeners as lib.com.mmm.postit.teams.core.js.CrdtCallback,
    maxDriftMs
  );
  clientApi.undoManager.addListener(
    new UndoListener(curry(onCanUndo)(clientApi), curry(onCanRedo)(clientApi))
  );
  return clientApi;
};

type SetPropertyParams = Parameters<typeof setProperty>;
export const setProperties = <
  T extends 'note' | 'group',
  U extends T extends 'note'
    ? Note
    : T extends 'group'
    ? Group
    : Record<string, never>
>(
  transaction: SetPropertyParams[0],
  id: SetPropertyParams[1],
  dataset: T,
  update: { [K in keyof U]?: U[K] }
) =>
  Object.entries(update)
    .filter(([, value]) => value)
    .forEach(([property, value]) =>
      setProperty(transaction, id, dataset, property, value)
    );

export const getFractionalIndex = (
  low = FractionalIndex.Companion.min,
  high = FractionalIndex.Companion.max
): FractionalIndex => FractionalIndex.Companion.between(low, high);

export const getSizeClassObject = KSizeClass.Companion.fromConstant.bind(
  KSizeClass.Companion
) as (sizeClass: SizeClass) => SizeClassObject;

export function mapToRecord2<K, V, R>(
  map: lib.com.mmm.postit.teams.common.collections.MapWrapper<K, V>,
  mapper: (value: V) => R
): Record<string, R> {
  const record: Record<string, R> = {};
  forEachMapElement(map, (key, value) => {
    record[`${key}`] = mapper(value);
  });

  return record;
}

export const toCssColor = (color: string) =>
  color.startsWith('#') ? color : `#${color}`;
export const getColor = (color: string) =>
  color.startsWith('#') ? color.substring(1) : color;
export function mapToRecord<K, V>(
  map: lib.com.mmm.postit.teams.common.collections.MapWrapper<K, V>
): Record<string, V> {
  return mapToRecord2(map, (value: V) => value);
}

export const extractOcrTextFromNote = (note: NoteElement) =>
  (note.text.raw ||
    note.ocr?.detectedText.substring(
      (note.ocr?.detectedText.indexOf('=') ?? 0) + 1,
      note.ocr?.detectedText.indexOf(', language')
    )) ??
  '';
