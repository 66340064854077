import { cloneDeep, flow, omit, round } from 'lodash';
import { P } from 'point.js';
import tinycolor from 'tinycolor2';

import { cast } from '../../apis/utils.schema';
import { SizeClass, textSchema, Vote } from '../../types/core.schema';
import {
  FontFamily,
  IS_PRODUCTION,
  NOTE_STACK_POSITION_PRESET,
  POINTER_BUTTONS
} from '../../utils/constants';
import { getSizeClassObject } from '../../utils/core';
import { createLogger } from '../../utils/generic';
import { Scene } from '../Scene';
import {
  CanvasElement,
  CursorElement,
  GroupElement,
  NoteElement,
  ViewportTransform
} from '../types';

import colors from 'styles/colors.module.scss';

export const getPixelRatio = () => window.devicePixelRatio;

export const getContext = (canvas: HTMLCanvasElement) =>
  canvas.getContext('2d')!;

export const getNormalizedCanvasSize = (canvas: HTMLCanvasElement) => ({
  width: canvas.width / getPixelRatio(),
  height: canvas.height / getPixelRatio()
});

export const initializeContext = ({
  context,
  vpt,
  shouldTrace,
  shouldClear = true
}: {
  context: CanvasRenderingContext2D;
  vpt: ViewportTransform;
  shouldTrace?: boolean;
  shouldClear?: boolean;
}) => {
  // Reset identity matrix in case someone forgot to reset
  context.setTransform(1, 0, 0, 1, 0, 0);
  // context.imageSmoothingEnabled = false

  const scale = getPixelRatio();
  context.scale(scale, scale);

  // When doing calculations based on canvas width we should used normalized one
  const { width, height } = getNormalizedCanvasSize(context.canvas);

  // Paint background
  context.fillStyle = '#fff';
  shouldTrace ||
    context[shouldClear ? 'clearRect' : 'fillRect'](0, 0, width, height);

  // Apply zoom
  context.translate(vpt.zoom.translation.x, vpt.zoom.translation.y);
  context.scale(vpt.zoom.value, vpt.zoom.value);
};

export const containCtx = <T>(
  func: (context: CanvasRenderingContext2D) => T,
  context: CanvasRenderingContext2D
) => {
  context.save();
  const res = func(context);
  context.restore();
  return res;
};

/**
 * Function for hard assertion via throw on fail.
 * @param condition The value that determents if an error is thrown
 * @param message The message that gets thrown if the condition is false
 * @return Returns boolean or never
 */
export const assert = (condition: unknown, message: string) => {
  if (!condition) throw Error(message);
  return true;
};

export const { warn } = createLogger('Silent Warning');
const alreadyAnnounced: string[] = [];
export const announceDeprecated = (
  deprecatedName: string,
  replacementName: string
) => {
  if (IS_PRODUCTION || alreadyAnnounced.some(v => v === deprecatedName)) return;
  alreadyAnnounced.push(deprecatedName);
  warn(
    `${deprecatedName} has been deprecated. Use ${replacementName} instead.`
  );
};

/**
 * Creates a function that returns the result of invoking the provided functions
 * with the this binding of the created function, where each successive invocation
 * is supplied the return value of the previous.
 * @param funcs Functions to invoke.
 * @return Returns the new function.
 */
export const pipe = flow;

/**
 * Function for light assertion via log on fail.
 * @param condition The value that determents if an error is logged
 * @param message The message that gets logged if the condition is false
 * @return Returns boolean
 */
export const assertSilently = (condition: unknown, message: string) =>
  !!condition || void warn(message) || false;

/**
 * Wrapper around `assert()` so that it can be used in a pipe.
 * @param condition Function that returns the value that determents if an error is thrown
 * @param message Function that returns the message that gets thrown if the condition is false
 * @return Returns a new function for asserting.
 */
export const refine =
  <T>(condition: (param: T) => unknown, message: (param: T) => string) =>
  /**
   * Asserting function that throws on fail or returns the passed value on success.
   * @param param Parameter to assert
   * @return Returns the passed parameter
   */
  (param: T): T | never => {
    assert(condition(param), message(param));
    return param;
  };

/**
 * @warning Typescript can't derive the opposite of a type-narrowing assertion. Instead, that type of operation needs to explicit.
 *
 * Creates a function that returns the opposite boolean value of the result of invoking the provided function.
 * (!func())
 * @param func Function to invoke on execution
 * @return Returns the new function
 */
export const not = <T extends (...args: any[]) => any>(func: T) =>
  pipe(func, v => !v);

/**
 * @warning Typescript can't derive the opposite of a type-narrowing assertion. Instead, that type of operation needs to explicit.
 *
 * Assignees a method `not()` to the provided function that returns the opposite boolean value of the result of invoking the provided function.
 * (func.not() === !func())
 * @param func Function to invoke on execution
 * @return Returns the new function
 */
export const withNot = <T extends (...args: any[]) => any>(func: T) =>
  Object.assign(func, { not: not<T>(func) }) as T & { not: T };

export const isDeletedElement = withNot(
  (element: CanvasElement) => 'deleted' in element && element.deleted
);

export const getSelectionStrokeColor = (
  element: CanvasElement,
  type: 'border' | 'handle' = 'border'
) =>
  'color' in element
    ? tinycolor(element.color)
        .darken(type === 'handle' ? 35 : 25)
        .toHexString()
    : colors.gray500;

export const isLeftClick = (e: MouseEvent) => e.button === POINTER_BUTTONS.MAIN;

export const fittingString = ({
  context,
  string,
  maxWidth
}: {
  context: CanvasRenderingContext2D;
  string: string;
  maxWidth: number;
}) => {
  const ellipsis = '…';
  const ellipsisWidth = context.measureText(ellipsis).width;
  let { width } = context.measureText(string);

  if (width <= maxWidth || width <= ellipsisWidth) {
    return string;
  }

  let { length } = string;
  while (width >= maxWidth - ellipsisWidth && length-- > 0) {
    string = string.substring(0, length);
    width = context.measureText(string).width;
  }
  return string + ellipsis;
};

export const getUserVotes = ({
  notes,
  userId
}: {
  notes: NoteElement[];
  userId: string;
}) =>
  notes.reduce((votes, note) => {
    const userVotes = note.votes.filter(vote => {
      return vote.userId === userId && vote.vote.location !== null;
    });
    userVotes.length > 0 && votes.push(...userVotes);
    return votes;
  }, [] as Vote[]);

export const groupElementsByElementType = (elements: CanvasElement[]) =>
  elements.reduce(
    (prev, element) => {
      const key = `${element.type}s` as `${CanvasElement['type']}s`;
      if (!(key in prev)) throw Error(`Unimplemented type ${element.type}`);
      prev[key].push(element as never);
      return prev;
    },
    {
      notes: [] as NoteElement[],
      groups: [] as GroupElement[]
    }
  );

export type PerformanceMetrics = {
  start: number;
  elapsed: number;
  execTime: number;
  avgExecTime: number;
  fps: number;
};
export const trackMetrics =
  (scene: Scene, additional?: string | (() => string)) =>
  <T extends (...params: unknown[]) => unknown>(func: T) => {
    const canvasContainer = document.querySelector('canvas')?.parentElement;
    const metrics = {
      start: 0,
      elapsed: 0,
      execTime: 0,
      fps: 0,
      avgFps: 0,
      frames: 0
    };
    let frameStart = window.performance.now();
    let frames = 0;

    const numberOf = {
      notes: 0,
      visibleNotes: 0,
      groups: 0,
      visibleGroups: 0,
      cursors: 0,
      visibleCursors: 0
    };

    const div =
      document.querySelector('#canvasMetrics') || document.createElement('div');
    div.id = 'canvasMetrics';
    (div as any).style = 'position: absolute; top: 100px; left: 10px;';
    const update = () => {
      div.innerHTML = `
			<p>Fps: ${round(metrics.fps, 2)}</p>
			<p>Avg. fps: ${round(metrics.avgFps, 2)}</p>
			<p>Exec. time: ${round(metrics.execTime, 2)}ms</p>
			${typeof additional === 'function' ? additional() : additional ?? ''}
			<p>notes: ${numberOf.notes}st</p>
			<p>groups: ${numberOf.groups}st</p>
			<p>cursors: ${numberOf.cursors}st</p>
		`;
    };
    update();
    canvasContainer?.append(div);

    return (...args: Parameters<T>) => {
      const start = window.performance.now();
      metrics.start ||= start;
      const v = func(...args);
      const end = window.performance.now();

      const dt = end - frameStart;
      ++frames;
      ++metrics.frames;
      if (dt > 1000) {
        metrics.fps = (frames * 1000) / dt;
        metrics.execTime = end - start;
        metrics.elapsed = end - metrics.start;
        metrics.avgFps = (metrics.frames * 1000) / metrics.elapsed;
        frames = 0;
        frameStart = end;

        const { notes, groups } = groupElementsByElementType(
          scene.getNonDeletedElements()
        );
        const cursors = scene.getConnectedCursors();

        Object.assign(numberOf, {
          notes: notes.length,
          groups: groups.length,
          cursors: cursors.length
        });
      }

      update();

      return v;
    };
  };

export const getText = (
  raw: string | null | undefined,
  sizeClass: SizeClass,
  withLineBreaks?: string,
  typeface?: FontFamily
) =>
  raw
    ? cast(textSchema, {
        contents: raw,
        contentSize: getSizeClassObject(sizeClass),
        displayString: withLineBreaks,
        typeface
      })
    : null;

const { log: debugLog } = createLogger('debug');

export const attachDebugValues = (values: Record<string, any>, log = false) => {
  if (log) debugLog('', values);
  else {
    const canvasContainer = document.querySelector('canvas')?.parentElement;

    const div =
      document.querySelector('#canvasMetrics') ||
      (document.createElement('div') as HTMLDivElement);
    canvasContainer?.append(div);
    div.id = 'canvasMetrics';
    (div as any).style =
      'position: absolute; top: 100px; left: 10px; white-space: pre-wrap;';
    const update = () =>
      (div.innerHTML = Object.entries(values)
        .map(([k, v]) => `${k}: ${JSON.stringify(v, null, 2)}`)
        .join('\n'));

    update();
  }
};

export const displayCache = (canvas: HTMLCanvasElement) => {
  let div = document.querySelector('#cachedCanvas') as HTMLDivElement | null;
  if (!div) {
    div = document.createElement('div') as HTMLDivElement;
    document.body.append(div);
  }
  div.id = 'cachedCanvas';
  (div as any).style = 'position: absolute; top: 100px; left: 10px;';
  div.innerHTML = '';
  div.append(canvas);
};

export const validateIsAllNotes = (
  items: CanvasElement[]
): items is NoteElement[] => items.every(item => item.type === 'note');

export const validateIsAllGroups = (
  items: CanvasElement[]
): items is GroupElement[] =>
  items.every(item => item.type === 'group' && !item.stacked);

export const validateIsAllStacks = (
  items: CanvasElement[]
): items is GroupElement[] =>
  items.every(item => item.type === 'group' && item.stacked);

export const extractNotes = (elements: CanvasElement[]) =>
  elements.reduce((notes, item) => {
    item.type === 'note' && notes.push(item);
    item.type === 'group' && notes.push(...item.notes);
    return notes;
  }, [] as NoteElement[]);

export const asTransient = <
  T extends CanvasElement | CursorElement | undefined
>(
  element: T
) =>
  (element && {
    ...element,
    ...('transientState' in element ? element.transientState : null)
  }) as T extends CursorElement
    ? CursorElement
    : T extends CanvasElement
    ? T & T['transientState']
    : undefined;

export const wrapperTrackClickType = <
  T extends { clientX: number; clientY: number }
>(
  fn: (event: T) => void,
  onChange: (type: 'single' | 'double' | 'triple' | undefined) => unknown
) => {
  let timer: NodeJS.Timeout | null = null;
  const prev = {
    position: undefined as Point | undefined,
    detail: 0
  };

  const reset = () => {
    timer !== null && clearTimeout(timer);
    timer = null;
    prev.detail = 0;
    prev.position = undefined;
    onChange(undefined);
  };

  return (event: T) => {
    timer !== null && clearTimeout(timer);

    const current = { x: event.clientX, y: event.clientY };

    if (prev.position && !P(prev.position).is(current)) reset();

    prev.position = current;
    prev.detail++;

    if (prev.detail === 1) onChange('single');
    else if (prev.detail === 2) onChange('double');
    else if (prev.detail === 3) onChange('triple');
    else onChange(undefined);

    timer = setTimeout(reset, 300);

    fn(event);
  };
};

export const circularDifference = (
  angle0: number,
  angle1: number,
  max = Math.PI * 2
) => {
  const diff = ((angle1 - angle0 + max / 2) % max) - max / 2;
  return diff < -(max / 2) ? diff + max : diff;
};

export const optimizeForCircularTransition = (
  from: number,
  to: number,
  max = Math.PI * 2
) => {
  const diff = circularDifference(to, from, max);
  return {
    from,
    to: from - diff,
    diff
  };
};

export type DraggedElement = {
  element: CanvasElement;
  newOrigin?: Point;
  isCanceled?: boolean;
};

export const restrictToTopOfStack = (
  note?: CanvasElement,
  parent?: CanvasElement
) =>
  !parent ||
  !note ||
  note.type !== 'note' ||
  parent.type !== 'group' ||
  !parent.stacked ||
  !parent.notes.includes(note) ||
  parent.notes.findIndex(item => item.id === note.id) ===
    parent.notes.length - 1;

export const applyShadowFiltering = (group: GroupElement) =>
  group.stacked &&
  group.notes.forEach(
    (note, i, arr) =>
      (note.transientState.isShadowless =
        !!arr[i + NOTE_STACK_POSITION_PRESET.length - 1])
  );

const noteOmitKeys = ['votes', 'transientState'] as const;
const groupOmitKeys = ['transientState'] as const;
export const cloneDeepEntity = <T extends CanvasElement>(entity: T) =>
  cloneDeep(
    omit(entity, entity.type === 'note' ? noteOmitKeys : groupOmitKeys)
  ) as T extends NoteElement
    ? Omit<NoteElement, typeof noteOmitKeys[number]>
    : T extends GroupElement
    ? Omit<GroupElement, typeof groupOmitKeys[number]>
    : Partial<T>;
