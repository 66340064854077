/* eslint-disable prefer-destructuring */
import colors from '../assets/styles/colors.module.scss';
import { refine } from '../canvas/utils/utils';
import { SizeClass, SIZE_CLASSES } from '../types/core.schema';
import { getSizeClassObject } from './core';
import { createLogger } from './generic';

export const COMMIT = process.env.COMMIT!;
export const VERSION = process.env.VERSION!;
export const ENVIRONMENT = (process.env.ENVIRONMENT ??
  (!Object.values(process.env).length ? 'styleguide' : undefined))!;
export const API_KEY = process.env.API_KEY!;
export const REST_URL = process.env.REST_URL!;
export const PUBLIC_URL = process.env.PUBLIC_URL!;
export const CONTENT_URL = process.env.CONTENT_URL!;
export const SENTRY_DSN = process.env.SENTRY_DSN;
export const FEEDBACK_URL = process.env.FEEDBACK_URL;
export const REST_API_VERSION = process.env.REST_API_VERSION!;

const SHOULD_OPTIMIZE_FOR_HEAP = false;

export const IS_MICROSOFT_TEAMS = !!process.env.IS_MICROSOFT_TEAMS;

export const IS_PRODUCTION =
  !!(process.env.IS_PRODUCTION && !localStorage?.['mmm-debug']) ||
  SHOULD_OPTIMIZE_FOR_HEAP;

export const IS_LOCALHOST =
  ENVIRONMENT === 'localhost' && !SHOULD_OPTIMIZE_FOR_HEAP;

export const IS_DEBUG = (!!process.env.DEVELOPMENT_ENVIRONMENT &&
  !SHOULD_OPTIMIZE_FOR_HEAP) as boolean;

export const IS_DARWIN = /Mac|iPod|iPhone|iPad/.test(window.navigator.platform);

if (IS_DEBUG)
  import('mmm-postit-teams-core/core').then(lib => {
    const TeamsCore = lib.com.mmm.postit.teams.core.TeamsCore;

    Object.assign(window, {
      TeamsCore: Object.assign(TeamsCore, lib.com.mmm.postit.teams)
    });

    createLogger('DEBUG').log(
      'System information',
      [
        '\n',
        `BACKEND: ${CONTENT_URL},`,
        ...(COMMIT?.length
          ? ['COMMIT: "', `\t${COMMIT.split('\n').join('\n\t')}`, '",']
          : []),
        'VERSIONS: [',
        [`\tCORE: ${TeamsCore.version} (${TeamsCore.protocolVersion})`].join(
          '\t'
        ),
        ']'
      ].join('\n')
    );
  });
export const DEBUG_MODES = {
  HANDLES: 'handle',
  HIT: 'hit',
  SHADOW: 'shadow',
  CACHE: 'cache',
  PERFORMANCE: 'performance',
  ANALYTICS: 'analytics',
  SIZE: 'size',
  ENTITY_MANAGER: 'entity_manager',
  EVENT_PATTERN: 'event_pattern',
  BACKGROUND: 'background',
  TEXT: 'text'
} as const;

export const DEBUG = {
  MODE:
    (IS_DEBUG &&
      Object.values(DEBUG_MODES).find(
        mode =>
          new URLSearchParams(document.location.search).get('debug') === mode
      )) ||
    undefined,
  CONSTANTS: [
    ...((!IS_PRODUCTION
      ? [
          ['CROSS', true],
          ['TRANSITION', true],
          ['STACK_COMPLETE', IS_LOCALHOST],
          ['SELECTION_TRANSITION', IS_LOCALHOST]
        ]
      : []) as [string, unknown][]),
    ...(!IS_PRODUCTION
      ? new URLSearchParams(document.location.search).entries()
      : [])
  ].reduce(
    (prev, [k, v]: [string, unknown]) => {
      k = k.toUpperCase();
      try {
        v = JSON.parse(v as string);
      } catch (e) {
        e;
      }
      (prev as any)[k] = v;
      return prev;
    },
    {} as Partial<{
      SCROLL: number;
      CURSOR: 'own' | 'origin';
      CROSS: boolean;
      CONTAINER: boolean;
      TRANSITION: boolean;
      ENABLE_MOBILE: boolean;
      STACK_COMPLETE: boolean;
      SELECTION_TRANSITION: boolean;
      HIDE: 'group' | 'note';
    }>
  )
};

export const POINTER_BUTTONS = {
  MAIN: 0,
  WHEEL: 1,
  SECONDARY: 2,
  TOUCH: -1
} as const;

export const ALPHABETICAL_KEYS = {
  A: 'a',
  C: 'c',
  D: 'd',
  G: 'g',
  H: 'h',
  M: 'm',
  N: 'n',
  S: 's',
  V: 'v',
  X: 'x',
  Y: 'y',
  Z: 'z'
} as const;

export const NUMERICAL_KEYS = {
  ONE: 'Digit1'
} as const;

export const ARROW_KEYS = {
  ARROW_DOWN: 'ArrowDown',
  ARROW_LEFT: 'ArrowLeft',
  ARROW_RIGHT: 'ArrowRight',
  ARROW_UP: 'ArrowUp'
} as const;

export const WHITESPACE_KEYS = {
  SPACE: ' ',
  TAB: 'Tab'
} as const;

export const ACTION_KEYS = {
  BACKSPACE: 'Backspace',
  DELETE: 'Delete',
  ENTER: 'Enter',
  ESCAPE: 'Escape'
} as const;

export const COMBINATIONAL_KEYS = {
  ALT: 'Alt',
  CTRL: 'ctrlKey',
  META: 'metaKey',
  SHIFT: 'shiftKey'
} as const;

export const ARTIHMETICAL_KEYS = {
  PLUS: '+',
  MINUS: '-'
} as const;

export const NON_COMBINATIONAL_KEYS = {
  ...ALPHABETICAL_KEYS,
  ...NUMERICAL_KEYS,
  ...ARROW_KEYS,
  ...WHITESPACE_KEYS,
  ...ACTION_KEYS,
  ...ARTIHMETICAL_KEYS
} as const;

export const QOL_KEYS = {
  CTRL_OR_CMD: `${IS_DARWIN ? 'metaKey' : 'ctrlKey'}` as const
};

export const KEYS = {
  ...NON_COMBINATIONAL_KEYS,
  ...COMBINATIONAL_KEYS,
  ...QOL_KEYS
};

// ! These need to be synced with the declarations in `BoardCanvas.scss`
export type Cursor = keyof typeof CSS_CURSORS;
export const CSS_CURSORS = {
  default: `board-canvas__canvas--cursor-default`,
  select: `board-canvas__canvas--cursor-pointer`,
  text: `board-canvas__canvas--cursor-text`,
  move: `board-canvas__canvas--cursor-grabbing`,
  clone: `board-canvas__canvas--cursor-copy`,
  panOff: `board-canvas__canvas--cursor-grab`,
  panOn: `board-canvas__canvas--cursor-grabbing`,

  resizeN: `board-canvas__canvas--cursor-ns-resize`,
  resizeS: `board-canvas__canvas--cursor-ns-resize`,
  resizeE: `board-canvas__canvas--cursor-ew-resize`,
  resizeW: `board-canvas__canvas--cursor-ew-resize`,
  resizeNE: `board-canvas__canvas--cursor-nesw-resize`,
  resizeSW: `board-canvas__canvas--cursor-nesw-resize`,
  resizeNW: `board-canvas__canvas--cursor-nwse-resize`,
  resizeSE: `board-canvas__canvas--cursor-nwse-resize`,

  rotateN: `board-canvas__canvas--cursor-n-rotate`,
  rotateS: `board-canvas__canvas--cursor-s-rotate`,
  rotateE: `board-canvas__canvas--cursor-e-rotate`,
  rotateW: `board-canvas__canvas--cursor-w-rotate`,
  rotateNE: `board-canvas__canvas--cursor-ne-rotate`,
  rotateSW: `board-canvas__canvas--cursor-sw-rotate`,
  rotateNW: `board-canvas__canvas--cursor-nw-rotate`,
  rotateSE: `board-canvas__canvas--cursor-se-rotate`,

  note: `board-canvas__canvas--cursor-note`,
  notAllowed: `board-canvas__canvas--cursor-not-allowed`
} as const;

export const CURSOR_PRIORITY = Object.keys(
  CSS_CURSORS
) as (keyof typeof CSS_CURSORS)[];

export const INCH_TO_MM = 25.4;

/**
 * Threshold from the viewport edge when edgescroll should start
 */
export const SCROLL_PADDING = 10;

/**
 * Number of pixels to scroll per second while edgescrolling
 */
export const SCROLL_PIXELS_SPEED = 300;

export const COPY_OFFSET = 10;

/* Zoom */

// Currently we do not draw bigger or smaller notes
// we simply adjust the zoom level by this adjusting `ZOOM_SCALE_FACTOR`
export const ZOOM_SCALE_FACTOR = 1.9;

export const ZOOM_DEFAULT = 1;

export const ZOOM_STEPS = [
  0.01,
  0.025,
  0.05,
  0.075,
  0.1,
  0.15,
  0.2,
  0.25,
  0.33,
  0.41,
  0.5,
  0.6,
  0.7,
  0.85,
  ZOOM_DEFAULT,
  1.125,
  1.25,
  1.375,
  1.5,
  1.75,
  2,
  2.25,
  2.5,
  2.75,
  3,
  3.5,
  4,
  4.5,
  5
].map(s => s * ZOOM_SCALE_FACTOR);

export const ZOOM_MIN = ZOOM_STEPS[0];

export const ZOOM_MAX = ZOOM_STEPS[ZOOM_STEPS.length - 1];

export const ZOOM_TO_FIT_PADDING = 100;

export const ZOOM_HIGH_RES = ZOOM_DEFAULT * ZOOM_SCALE_FACTOR;

export const ZOOM_STEPS_CACHE = [
  0.25,
  0.5,
  0.75,
  ZOOM_DEFAULT,
  1.25,
  1.5,
  1.75,
  2,
  2.5,
  3,
  3.5,
  4,
  4.5,
  5
].map(s => s * ZOOM_SCALE_FACTOR);

export const ZOOM_MIN_CACHE = ZOOM_STEPS_CACHE[0];

export const ZOOM_MAX_CACHE = ZOOM_STEPS_CACHE[ZOOM_STEPS_CACHE.length - 1];

/* Viewport */

export const DEFAULT_VPT = {
  zoom: {
    value: ZOOM_DEFAULT * ZOOM_SCALE_FACTOR,
    translation: { x: 0, y: 0 }
  },
  scrollX: 0,
  scrollY: 0
};

export const VIEWPORT_LIMIT_MARGIN = -INCH_TO_MM / 4;

/* Transition */

const mediaQuery = window.matchMedia('(prefers-reduced-motion: reduce)');
export const DISABLE_TRANSITIONS = () => mediaQuery.matches;

/**
 * Duration in ms to transition general things in the canvas
 */
export const DEFAULT_TRANSITION_DURATION = 100;

/**
 * Default scale of a note
 */
export const DEFAULT_SCALE = 1;

/**
 * Default opacity
 */
export const DEFAULT_OPACITY = 1;

/**
 * Ghost opacity
 */
export const GHOST_OPACITY = 0.9;

/* Typography */

export type FontFamily = Values<typeof FONT_FAMILIES>;
export const FONT_FAMILIES = {
  'cera-pro': 'cera-pro',
  'roboto-slab': 'roboto-slab',
  kalam: 'kalam',
  'permanent-marker': 'permanent-marker',
  'noto-sans': 'noto-sans'
} as const;

export const DEFAULT_FONT_FAMILY = FONT_FAMILIES['cera-pro'];

export const LINE_HEIGHT_RATIO = 1.5;

export const DEFAULT_FONT_SIZE = 10;

/* Images */

/**
 * What zoom is considered low resolution
 */
export const LOW_RES_FACTOR = ZOOM_DEFAULT * ZOOM_SCALE_FACTOR;

/**
 * What zoom is considered high resolution
 */
export const HIGH_RES_FACTOR = ZOOM_DEFAULT * 2 * ZOOM_SCALE_FACTOR;

/* Element */

/**
 * The opacity applied on an element when marked with `isFaded`
 */
export const ELEMENT_IS_FADED_OPACITY = 0.25;

/**
 * The maximum total number of votes available to each user
 */
export const MAX_NUMBER_OF_VOTES = 5;

// FIXME: maybe not the best way to bust the cache
/**
 * Properties that should break cache if changed
 */
export const CACHE_BUSTING_KEYS = [
  'size',
  'sizeClass',
  'color',
  'text',
  'base',
  'baseLayer',
  'drawing',
  'drawLayer',
  'rotation',
  'transientState',
  'typeface',
  'alignment',
  'listType'
] as const;

/* Note element */

export const NOTE_SIZE = {
  S: 's',
  M: 'm',
  L: 'l'
} as const;
export type NoteSize = typeof NOTE_SIZE[keyof typeof NOTE_SIZE];

export const NOTE_SHAPE = {
  SQR: 'sqr',
  VER: 'ver',
  HOR: 'hor'
} as const;
export type NoteShape = typeof NOTE_SHAPE[keyof typeof NOTE_SHAPE];

export const NOTE_SIZE_CLASSES_SIZE_AND_SHAPE = {
  '3x3': [NOTE_SIZE.S, NOTE_SHAPE.SQR],
  '5x5': [NOTE_SIZE.M, NOTE_SHAPE.SQR],
  '7x7': [NOTE_SIZE.L, NOTE_SHAPE.SQR],
  '5x3': [NOTE_SIZE.S, NOTE_SHAPE.HOR],
  '7x5': [NOTE_SIZE.M, NOTE_SHAPE.HOR],
  '10x7': [NOTE_SIZE.L, NOTE_SHAPE.HOR],
  '3x5': [NOTE_SIZE.S, NOTE_SHAPE.VER],
  '5x7': [NOTE_SIZE.M, NOTE_SHAPE.VER],
  '7x10': [NOTE_SIZE.L, NOTE_SHAPE.VER]
} as const;

export const NOTE_SIZE_CLASSES_LABELS = {
  [NOTE_SIZE.S]: 'Small',
  [NOTE_SIZE.M]: 'Medium',
  [NOTE_SIZE.L]: 'Large',
  [NOTE_SHAPE.SQR]: 'Square',
  [NOTE_SHAPE.HOR]: 'Horizontal',
  [NOTE_SHAPE.VER]: 'Vertical'
} as const;

export const NOTE_SIZE_CLASSES_ACTUAL_SIZES = Object.fromEntries(
  Object.entries(SIZE_CLASSES).map(([k]) => [
    k,
    getSizeClassObject(k as SizeClass).noteSize
  ])
) as Record<SizeClass, Size>;

/**
 * The scale of a note when its "lifted off the board"
 */
export const NOTE_LIFTED_SCALE = 1.05;
/**
 * Padding around note text
 */
export const NOTE_PADDING = Math.ceil(3 * INCH_TO_MM * 0.05); // 5% of a 3x3 note/**
/**
 * Note Minibar Padding
 */
export const NOTE_MINIBAR_PADDING = 8;

/**
 * Maximum number of characters for a notes text
 */
export const NOTE_MAX_TEXT_LENGTH = 500;

/**
 * Width of the stroke around a note
 */
export const NOTE_STROKE_WIDTH = 0.5;

/**
 * Multiple to lock notes rotational angle to while rotating with shift
 */
export const NOTE_SHIFT_LOCKING_ANGLE = Math.PI / 8;

/* Group element */

/**
 * Default size of a group (small note * 2)
 */
export const DEFAULT_GROUP_SIZE = {
  width: NOTE_SIZE_CLASSES_ACTUAL_SIZES['3x3'].width,
  height: NOTE_SIZE_CLASSES_ACTUAL_SIZES['3x3'].height
} as const;

/**
 * Padding between group and its content
 */
export const GROUP_BORDER_PADDING = 8;

/**
 * Padding for group label container
 */
export const GROUP_LABEL_PADDING = 3;

/**
 * Fontsize of group label
 */
export const GROUP_LABEL_FONTSIZE = 8;

/**
 * Height of group label based on several constants
 */
export const GROUP_LABEL_HEIGHT =
  GROUP_LABEL_FONTSIZE * LINE_HEIGHT_RATIO + GROUP_LABEL_PADDING * 2;

/**
 * Maximum number of characters for a notes text
 */
export const GROUP_LABEL_MAX_CHAR_LENGTH = 50;

/**
 * Minimum group width (excluding padding from `GROUP_LABEL_PADDING`)
 */
export const GROUP_LABEL_MIN_WIDTH = 50;

/* UI Element */

/**
 * Scale of the UI element
 */
export const UI_ELEMENT_SCALE = ZOOM_DEFAULT * ZOOM_SCALE_FACTOR;

/* Cursor element */

/**
 * Color of a cursor with its contrast for the pills label text color
 */
export const CURSOR_ELEMENT_COLOR_WITH_CONTRAST = {
  '#f05237': '#fff',
  '#ff9350': '#000',
  '#ffce2d': '#000',
  '#b7d664': '#000',
  '#79c47a': '#fff',
  '#56c4e8': '#000',
  '#3aa8b8': '#fff',
  '#91a0e2': '#fff',
  '#c39acb': '#fff',
  '#fa6d9c': '#fff'
} as const;
export type CursorElementColor =
  keyof typeof CURSOR_ELEMENT_COLOR_WITH_CONTRAST;

/**
 * Cursor position offset, (cursor is aligned to be drawn like an aabb)
 */
export const CURSOR_ELEMENT_POSITION_OFFSET = {
  x: -4,
  y: -4
};

/**
 * Margin between the cursor and its label pill, in both x and y
 */
export const CURSOR_ELEMENT_MARGIN = 5;

/**
 * Font of cursor label
 */
export const CURSOR_ELEMENT_FONT = {
  fontSize: 6,
  fontFamily: FONT_FAMILIES['cera-pro']
};

/**
 * Padding of the cursor pill
 */
export const CURSOR_ELEMENT_PADDING = {
  x: 2,
  y: 1
} as const;

/**
 * Scale of whole cursor element
 */
export const CURSOR_ELEMENT_CURSOR_SCALE = 0.7;

/* Selection */

/**
 * Padding between selected element and its border
 */
export const SELECTION_BORDER_PADDING = 0;

/* Handles */

/**
 * Scale of resize handle hit area
 * Important to get the dynamic `PointerType` when utilizing.
 */
export const HANDLE_SIZES = {
  mouse: 16,
  pen: 20,
  touch: 28
} as const;

/**
 * Scale of rotation handle hit area in relativity to `HANDLE_SIZES` used for resize
 * Should be applied together with `HANDLE_SIZES`
 * @example HANDLE_SIZES['mouse'] * HANDLE_SIZES_ROTATION_SCALE
 */
export const HANDLE_SIZES_ROTATION_SCALE = 2;

/**
 * Scale of resize handles radius
 * Should be applied in conjunction with `HANDLE_SIZES`
 */
export const HANDLE_RADIUS_SCALE = 1 / 3.5;

/**
 * Allow rendering a bit further out then the size of the actual element.
 * Consequently, allows for stroke and box shadow to be used when painting to a cached canvas
 *
 * FYI: In an optimal situation, the goal is to make this number as small as possible,
 * but making it to small or applying it incorrectly might cause things to look "cut-off"
 */
export const OFF_CANVAS_PADDING = 20;

export const AUTH = {
  redirectUri: process.env.AUTH_REDIRECT_URL!,
  loginUrl: `https://${process.env
    .AUTH_OKTA_DOMAIN!}/oauth2/default/v1/authorize`,
  tokenUrl: `https://${process.env.AUTH_OKTA_DOMAIN!}/oauth2/default/v1/token`,
  logoutUrl: `https://${process.env
    .AUTH_OKTA_DOMAIN!}/oauth2/default/v1/logout`,
  clientId: process.env.AUTH_CLIENT_ID!,
  responseMode: process.env.AUTH_RESPONSE_MODE!,
  grantType: 'authorization_code',
  responseType: 'code',
  scope: 'openid profile offline_access email'
} as const;
ENVIRONMENT !== 'styleguide' &&
  Object.entries(AUTH).forEach(
    refine(
      ([, v]) => typeof v === 'string',
      ([k]) => `Missing environmental variable ${k}`
    )
  );

export const AUTH_STORAGE_KEY = 'mmm.postit.is4';

// Refresh token 15min before expiration
export const TOKEN_EXPIRY_ADJUSTMENT = 15 * 60;

export const NOTE_COLOR_LABELS = {
  [`${colors.firstSnow}`]: 'First Snow',
  [`${colors.pinkSalt}`]: 'Pink Salt',
  [`${colors.positivelyPink}`]: 'Positively Pink',
  [`${colors.tropicalPink}`]: 'Tropical Pink',
  [`${colors.powerPink}`]: 'Power Pink',
  [`${colors.guava}`]: 'Guava',
  [`${colors.papayaFizz}`]: 'Papaya Fizz',
  [`${colors.vitalOrange}`]: 'Vital Orange',
  [`${colors.candyAppleRed}`]: 'Candy Apple Red',
  [`${colors.sunnyside}`]: 'Sunnyside',
  [`${colors.citron}`]: 'Citron',
  [`${colors.canaryYellow}`]: 'Canary Yellow',
  [`${colors.acidLime}`]: 'Acid Lime',
  [`${colors.limeade}`]: 'Limeade',
  [`${colors.luckyGreen}`]: 'Lucky Green',
  [`${colors.seaGlass}`]: 'Sea Glass',
  [`${colors.blueParadise}`]: 'Blue Paradise',
  [`${colors.aquaSplash}`]: 'Aqua Splash',
  [`${colors.freshMint}`]: 'Fresh Mint',
  [`${colors.washedDenim}`]: 'Washed Denim',
  [`${colors.moonstone}`]: 'Moonstone',
  [`${colors.irisInfusion}`]: 'Iris Infusion',
  [`${colors.orchidFrost}`]: 'Orchid Frost',
  [`${colors.pebbleGray}`]: 'Pebble Gray'
};

export type Shortcuts =
  | 'viewMode'
  | 'defaultMode'
  | 'newNote'
  | 'cancel'
  | 'delete'
  | 'edit'
  | 'centerCanvas'
  | 'pan'
  | 'undo'
  | 'redo'
  | 'selectAll'
  | 'zoomIn'
  | 'zoomOut'
  | 'sortGroup'
  | 'newGroup';

type ShortcutChecker = (event: KeyboardEvent) => boolean;
export const SHORTCUTS: Record<Shortcuts, ShortcutChecker> = {
  viewMode: e => e.key === 'H' && e.shiftKey,
  defaultMode: e => e.key === 'V' && e.shiftKey,
  newNote: e => e.key === 'N' && e.shiftKey,
  cancel: e => e.key === KEYS.ESCAPE,
  delete: e => e.key === KEYS.BACKSPACE || e.key === KEYS.DELETE,
  edit: e => e.key === KEYS.ENTER,
  centerCanvas: e =>
    (e.code === NUMERICAL_KEYS.ONE && (e.ctrlKey || e.metaKey || e.shiftKey)) ||
    ((e.ctrlKey || e.metaKey) && e.shiftKey && e.key.toLowerCase() === KEYS.C),
  pan: e => e.key === KEYS.SPACE,
  undo: e => e.key === KEYS.Z && (e.ctrlKey || e.metaKey),
  redo: e =>
    (e.key === KEYS.Y && (e.ctrlKey || e.metaKey)) ||
    (e.key === KEYS.Z && (e.ctrlKey || e.metaKey) && e.shiftKey),
  selectAll: e => e.key === KEYS.A && (e.ctrlKey || e.metaKey),
  zoomIn: e => e.key === KEYS.PLUS && (e.ctrlKey || e.metaKey),
  zoomOut: e => e.key === KEYS.MINUS && (e.ctrlKey || e.metaKey),
  sortGroup: e =>
    e.key === KEYS.S && (e.ctrlKey || e.metaKey) && IS_MICROSOFT_TEAMS,
  newGroup: e => e.key === KEYS.G && (e.ctrlKey || e.metaKey)
};

export const BOARD_TITLE_MAX_LENGTH = 50;

export const NOTE_STACK_POSITION_PRESET: {
  offsetY: number;
  rotation: number;
}[] = [
  {
    offsetY: 0,
    rotation: 0
  },
  {
    offsetY: 2,
    rotation: -5
  },
  {
    offsetY: 4,
    rotation: 5
  },
  {
    offsetY: 6,
    rotation: -3
  }
].map(v => Object.assign(v, { rotation: v.rotation * (Math.PI / 180) }));

/** Analytics */
export const TRACKING_COLORS = {
  [`${colors.candyAppleRed}`]: 'Candy Apple Red',
  [`${colors.sunnyside}`]: 'Sunnyside',
  [`${colors.luckyGreen}`]: 'Lucky Green',
  [`${colors.seaGlass}`]: 'Sea Glass',
  [`${colors.moonstone}`]: 'Moonstone',
  [`${colors.irisInfusion}`]: 'Iris Infusion',
  [`${colors.powerPink}`]: 'Power Pink',
  [`${colors.guava}`]: 'Guava',
  [`${colors.papayaFizz}`]: 'Papaya Fizz',
  [`${colors.citron}`]: 'Citron',
  [`${colors.limeade}`]: 'Limeade',
  [`${colors.aquaSplash}`]: 'Aqua Splash',
  [`${colors.blueParadise}`]: 'Blue Paradise',
  [`${colors.orchidFrost}`]: 'Orchid Frost',
  [`${colors.tropicalPink}`]: 'Tropical Pink',
  [`${colors.pebbleGray}`]: 'Pebble Gray',
  [`${colors.canaryYellow}`]: 'Canary Yellow',
  [`${colors.acidLime}`]: 'Acid Lime',
  [`${colors.freshMint}`]: 'Fresh Mint',
  [`${colors.washedDenim}`]: 'Washed Denim',
  [`${colors.positivelyPink}`]: 'Positively Pink',
  [`${colors.pinkSalt}`]: 'Pink Salt'
};

export const TRACKING_NOTE_SIZES = {
  [NOTE_SIZE.S]: 'small',
  [NOTE_SIZE.M]: 'medium',
  [NOTE_SIZE.L]: 'large'
} as const;

export const TRACKING_NOTE_SHAPES = {
  [NOTE_SHAPE.HOR]: 'wide',
  [NOTE_SHAPE.SQR]: 'square',
  [NOTE_SHAPE.VER]: 'tall'
} as const;

export const REM_TO_PX = 16;
