import clsx from 'clsx';
import { merge } from 'lodash';
import React, { ButtonHTMLAttributes, forwardRef, useMemo } from 'react';

export type ButtonSize = Values<typeof BUTTON_SIZE>;
const BUTTON_SIZE = {
  SM: 'sm',
  MD: 'md',
  LG: 'lg'
} as const;
export const validateButtonSize = (size: string): size is ButtonSize =>
  Object.values(BUTTON_SIZE).some(s => s === size);

export type ButtonTheme = Values<typeof BUTTON_THEME>;
const BUTTON_THEME = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  TERTIARY: 'tertiary',
  NAKED: 'naked'
} as const;
const DEFAULT_BUTTON_THEME = 'secondary' as ButtonTheme;
export const validateButtonTheme = (size: string): size is ButtonTheme =>
  Object.values(BUTTON_THEME).some(s => s === size);

export type ButtonType = Values<typeof BUTTON_TYPE>;
const BUTTON_TYPE = {
  LINK: 'link',
  ICON: 'icon',
  REGULAR: 'regular'
} as const;
const DEFAULT_BUTTON_TYPE = 'regular' as ButtonType;
export const validateButtonType = (size: string): size is ButtonType =>
  Object.values(BUTTON_TYPE).some(s => s === size);

export type ButtonVariant = CombineLiteral<ButtonSize, ButtonTheme, ButtonType>;
export type ButtonSplitVariant = ButtonSize | ButtonTheme | ButtonType;

export const resolveButtonVariantObject = (
  variant?: ButtonVariant,
  defaultValue?: {
    size?: ButtonSize;
    theme?: ButtonTheme;
    type?: ButtonType;
  }
) => {
  const resolvedVariant = merge(
    { theme: DEFAULT_BUTTON_THEME, type: DEFAULT_BUTTON_TYPE },
    defaultValue
  );

  variant?.split(' ').forEach(v => {
    validateButtonSize(v) && (resolvedVariant.size = v);
    validateButtonTheme(v) && (resolvedVariant.theme = v);
    validateButtonType(v) && (resolvedVariant.type = v);
  });
  return {
    ...resolvedVariant,
    // QoL property for RoughButton
    string: `${resolvedVariant.type} ${resolvedVariant.theme} ${
      resolvedVariant.size ?? ''
    }`.trim() as ButtonVariant
  };
};

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: ButtonVariant;
  text?: string;
  loading?: boolean;
  active?: boolean;
}

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      type = 'button',
      variant,
      className,
      children,
      loading,
      disabled,
      text,
      active,
      ...rest
    },
    ref
  ) => {
    const variants = useMemo(
      () => resolveButtonVariantObject(variant).string.split(' '),
      [variant]
    );

    const classes = clsx(
      'btn',
      variants.map(v => `btn--${v}`),
      {
        'btn--loading': loading
      },
      className
    );

    return (
      <button
        ref={ref}
        className={classes}
        disabled={typeof disabled !== 'undefined' ? disabled : loading}
        aria-pressed={active}
        // eslint-disable-next-line react/button-has-type
        type={type}
        {...rest}
      >
        {text || children}
      </button>
    );
  }
);

// Otherwise name is hidden by the forward ref
Button.displayName = 'Button';
