/* eslint-disable no-template-curly-in-string */

import { InferType, mixed, number, object, string } from 'yup';

export const GRANT_TYPES = {
  authorization_code: 'authorization_code',
  refresh_token: 'refresh_token'
} as const;
export const grantTypeSchema = mixed<Values<typeof GRANT_TYPES>>()
  .test({
    name: 'grantType',
    message: '${path} must be a key of `GRANT_TYPES`',
    test: value => string().oneOf(Object.values(GRANT_TYPES)).isValidSync(value)
  })
  .required();

export const tokenSchema = object({
  access_token: string().required(),
  expires_in: number().required(),
  expires_at: number().required(),
  id_token: string().required(),
  refresh_token: string().required(),
  scope: string().required(),
  token_type: string().required()
})
  .required()
  .transform(v => {
    v.expires_at = Math.floor(Date.now() / 1000) + v.expires_in;
    return v;
  });
export type Token = InferType<typeof tokenSchema>;
