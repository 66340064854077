import { Nullable } from 'mmm-postit-teams-core';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useMedia } from 'react-use';

import breakpoints from 'styles/breakpoints.module.scss';

export const useDelayedRender = (delay: number) => {
  const [delayed, setDelayed] = useState(true);
  useEffect(() => {
    const timeout = window.setTimeout(() => setDelayed(false), delay);
    return () => clearTimeout(timeout);
  }, [delay]);
  return (fn: () => any) => !delayed && fn();
};

export const useMediaUp = (breakpoint: string) => {
  const bp = breakpoints[breakpoint];
  if (!bp) throw new Error(`Invalid breakpoint ${breakpoint}`);
  return useMedia(`(min-width: ${bp})`);
};

export const useMediaDown = (breakpoint: string) => {
  let bp = breakpoints[breakpoint];
  if (!bp) throw new Error(`Invalid breakpoint ${breakpoint}`);
  const numberBp = +bp.replace('px', '') || 0;
  bp = `${numberBp > 0 ? numberBp - 0.02 : 0}px`;
  return useMedia(`(max-width: ${bp})`);
};

export const useEventHandlers = (
  target: Nullable<EventTarget> | MediaQueryList,
  eventMap: Record<string, (...args: any[]) => any>,
  condition = true
) => {
  const events = useMemo(() => Object.entries(eventMap), [eventMap]);

  useEffect(() => {
    if (!target || !condition) return;
    events.forEach(([type, callback]) =>
      target.addEventListener(
        type,
        callback as EventListenerOrEventListenerObject
      )
    );
    return () => {
      events.forEach(([type, callback]) =>
        target.removeEventListener(
          type,
          callback as EventListenerOrEventListenerObject
        )
      );
    };
  }, [target, condition, events]);
};

export function useBroadcastChannel<T>(channelName: string) {
  const channel = useRef<BroadcastChannel | null>(null);
  const [message, setMessage] = useState<T>();

  useEffect(() => {
    channel.current = new BroadcastChannel(channelName);

    channel.current.onmessage = e => setMessage(e.data);

    return channel.current!.close;
  }, [channelName]);

  const postMessage = (newMessage: T) => {
    if (!channel.current) return;

    channel.current.postMessage(newMessage);
  };

  return { message, postMessage };
}
