import { object, string } from 'yup';

import { authenticatedAxios } from '../utils';
import { cast } from '../utils.schema';

export const getOnboarding = async () => {
  const authAxios = await authenticatedAxios();
  return cast(
    object({
      link: string().required()
    }),
    (await authAxios.get('users/me/onboarding')).data
  );
};
