import { useMutation, useQuery } from 'react-query';

import { authenticatedAxios } from '../../../src/apis/utils';

export const SESSION_BOARD_KEY = ['session-board'];

const getBoardBySessionId = async (sessionId?: string) => {
  const axios = await authenticatedAxios();
  const { data } = await axios.get(
    `microsoft-teams-sessions/${sessionId}/board`
  );
  return data;
};

const associateBoardBySessionId = async (
  sessionId: string,
  boardId: string
) => {
  const axios = await authenticatedAxios();
  return axios.put(`boards/${boardId}/microsoft-teams-sessions/${sessionId}`);
};

const disassociateBoardBySessionId = async (
  sessionId: string,
  boardId: string
) => {
  const axios = await authenticatedAxios();
  return axios.delete(
    `boards/${boardId}/microsoft-teams-sessions/${sessionId}`
  );
};

export const useGetBoardBySessionId = (sessionId?: string) =>
  useQuery(
    [...SESSION_BOARD_KEY, sessionId],
    () => getBoardBySessionId(sessionId),
    { retry: 0, enabled: !!sessionId }
  );

export const useAssociateBoardBySessionId = () =>
  useMutation(
    ({ sessionId, boardId }: { sessionId: string; boardId: string }) =>
      associateBoardBySessionId(sessionId, boardId)
  );

export const useDisassociateBoardBySessionId = () =>
  useMutation(
    ({ sessionId, boardId }: { sessionId: string; boardId: string }) =>
      disassociateBoardBySessionId(sessionId, boardId)
  );
