import { User } from '../../apis/user/user.schema';
import { Onboarding } from '../../types/onboarding';
import { createPersistedStore } from '../utils';

const defaultState = {
  user: {
    id: '',
    profile: { email: '', name: { first: '', last: '' } }
  } as User,
  onboarding: { link: '' } as Onboarding
};

export const useUserStore = createPersistedStore(
  defaultState,
  set => ({
    reset: () => set(defaultState),
    setUser: (user: User) => set({ user }),
    setOnboarding: (onboarding: Onboarding) => set({ onboarding })
  }),
  {
    name: 'settings',
    storage: localStorage,
    whitelist: ['user']
  }
);
