import clsx from 'clsx';
import { HTMLAttributes } from 'react';

interface Props extends HTMLAttributes<HTMLDivElement> {
  className?: string;
  variant?: 'round' | 'plane';
  size?: 'lg';
}

export const Spinner = ({ className, variant = 'round', size }: Props) => {
  return (
    <div
      className={clsx(
        'spinner',
        `spinner__${variant}`,
        size && `spinner__${variant}--${size}`,
        className
      )}
      role="status"
    >
      <span className="visually-hidden">Loading...</span>
    </div>
  );
};
