import React from 'react';
import { Navigate } from 'react-router';

import { useAuthStore } from '../../../../../src/stores/AuthStore/useAuthStore';

type Props = {
  children: JSX.Element;
};

export const RequireAuth = ({ children }: Props) => {
  const isLoggedIn = useAuthStore(state => state.authenticated);
  const path = window.location.pathname;
  return isLoggedIn ? children : <Navigate to={`/auth-start?from=${path}`} />;
};
