import { Token } from '../../types/auth';
import { getUuid } from '../../utils/core';
import { useBoardsStore } from '../BoardsStore/useBoardsStore';
import { useUserStore } from '../UserStore/useUserStore';
import { createPersistedStore } from '../utils';

export type AuthStoreState = typeof defaultState;
const defaultState = {
  token: null as Token | null,
  authenticated: false,
  apiVersionTooOld: false
};

export const getDeviceId = () => {
  let id = window.sessionStorage.getItem('deviceId');
  if (!id) {
    id = getUuid().toString();
    window.sessionStorage.setItem('deviceId', id);
  }
  return id;
};

export const useAuthStore = createPersistedStore(
  defaultState,
  set => ({
    setToken: (token: Token) => set({ token, authenticated: true }),
    logout: () => {
      useBoardsStore.getState().reset();
      useUserStore.getState().reset();
      set(defaultState);
    }
  }),
  { name: 'auth', storage: localStorage }
);
