// Has to be imported first for styles specificity
import '../../src/assets/styles/index.scss';
import './assets/styles/index.scss';

import { MsalProvider } from '@azure/msal-react';
import { mergeThemes, Provider, teamsTheme } from '@fluentui/react-northstar';
import * as microsoftTeams from '@microsoft/teams-js';
import React, { Suspense } from 'react';
import { render } from 'react-dom';
import { QueryClientProvider } from 'react-query';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import { queryClient } from '../../src/apis/utils';
import { ApiVersionErrorModal } from '../../src/components/app/ApiVersionErrorModal/ApiVersionErrorModal';
import { Delayed } from '../../src/components/app/Delayed/Delayed';
import { NotFound } from '../../src/pages/not-found/NotFound/NotFound';
import { setupSentry } from '../../src/services/sentry';
import { createLogger } from '../../src/utils/generic';
import { RequireAuth } from './components/app/RequireAuth/RequireAuth';
import AuthRedirect from './pages/auth-redirect/AuthRedirect';
import AuthStart from './pages/auth-start/AuthStart';
import AuthUpdate from './pages/auth-update/AuthUpdate';
import Start from './pages/start/Start';
import { msalInstance, setupTeamsAuth } from './services/auth';

const logger = createLogger('teams-index');

const Boards = React.lazy(() => import('./pages/boards/Boards'));
const Configure = React.lazy(() => import('./pages/configure/Configure'));
const TeamsBoard = React.lazy(
  () => import('./pages/teams-board/TeamsBoard/TeamsBoard')
);
const SidePanel = React.lazy(() => import('./pages/side-panel/SidePanel'));

const MsftAuthUpdate = React.lazy(
  () => import('./pages/msft-auth-update/MsftAuthUpdate')
);
const MsftAuthRedirect = React.lazy(
  () => import('./pages/msft-auth-redirect/MsftAuthRedirect')
);

const theme = {
  componentStyles: {
    Provider: {
      root: () => ({
        backgroundColor: 'transparent'
      })
    }
  },
  fontFaces: []
};

const App = () => (
  <Provider theme={mergeThemes(teamsTheme, theme)}>
    <MsalProvider instance={msalInstance}>
      <QueryClientProvider client={queryClient}>
        <Suspense fallback={<Delayed>Loading...</Delayed>}>
          <ApiVersionErrorModal />
          <BrowserRouter>
            <Routes>
              <Route path="/auth-start" element={<AuthStart />} />
              <Route path="/auth-update" element={<AuthUpdate />} />
              <Route path="/login/callback" element={<AuthRedirect />} />
              <Route path="/msft-auth-update" element={<MsftAuthUpdate />} />
              <Route path="/auth/callback" element={<MsftAuthRedirect />} />
              <Route
                path="/"
                element={
                  <RequireAuth>
                    <Start />
                  </RequireAuth>
                }
              />
              <Route
                path="/configure"
                element={
                  <RequireAuth>
                    <Configure />
                  </RequireAuth>
                }
              />
              <Route
                path="/boards"
                element={
                  <RequireAuth>
                    <Boards />
                  </RequireAuth>
                }
              />
              <Route
                path="/board/:id"
                element={
                  <RequireAuth>
                    <TeamsBoard />
                  </RequireAuth>
                }
              />
              <Route
                path="/side-panel"
                element={
                  <RequireAuth>
                    <SidePanel />
                  </RequireAuth>
                }
              />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </BrowserRouter>
        </Suspense>
      </QueryClientProvider>
    </MsalProvider>
  </Provider>
);

microsoftTeams.initialize(() => {
  microsoftTeams.getContext(async teamsContext => {
    try {
      await setupTeamsAuth(teamsContext.loginHint);
    } catch (err) {
      logger.error(`${err}`);
    }
  });
});

setupSentry();

render(<App />, document.querySelector('#__postitteams'));

/** Delete the Fluent UI static css import. It overrides our css resets with normalize.css.
 * The Fluent UI gets appended at runtime when the Provider element is rendered. */
document.querySelector('[data-fela-type="STATIC"]')?.remove();
