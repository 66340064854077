import { TEAMS_FEATURE_FLAGS, IDP_ID } from '../../teams/src/utils/constants';
import { getToken, refreshToken } from '../apis/auth/getToken';
import { getCurrentUser } from '../apis/user/getCurrentUser';
import { getOnboarding } from '../apis/user/getOnboarding';
import { useAuthStore } from '../stores/AuthStore/useAuthStore';
import { useUserStore } from '../stores/UserStore/useUserStore';
import { getKey } from '../stores/utils';
import { Token } from '../types/auth';
import {
  AUTH,
  AUTH_STORAGE_KEY,
  IS_MICROSOFT_TEAMS,
  TOKEN_EXPIRY_ADJUSTMENT
} from '../utils/constants';
import { createLogger } from '../utils/generic';
import { trackLoginBegin, trackLoginComplete } from './analytics';

const { log } = createLogger('auth');

const getCrypto = () => (window.crypto || (window as any).msCrypto) as Crypto;
const getCryptoSubtle = () =>
  getCrypto().subtle || (getCrypto() as any).webkitSubtle;

const createRandomString = () => {
  const charset =
    '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz-_~.';
  let random = '';
  const randomValues = Array.from(
    getCrypto().getRandomValues(new Uint8Array(43))
  );
  randomValues.forEach(v => (random += charset[v % charset.length]));
  return random;
};

const sha256 = async (s: string) => {
  const digestOp: any = getCryptoSubtle().digest(
    { name: 'SHA-256' },
    new TextEncoder().encode(s)
  );

  // msCrypto (IE11) uses the old spec, which is not Promise based
  // https://msdn.microsoft.com/en-us/expression/dn904640(v=vs.71)
  // Instead of returning a promise, it returns a CryptoOperation
  // with a result property in it.
  // As a result, the various events need to be handled in the event that we're
  // working in IE11 (hence the msCrypto check). These events just call resolve
  // or reject depending on their intention.
  if ((window as any).msCrypto) {
    return new Promise((resolve, reject) => {
      digestOp.oncomplete = (e: any) => resolve(e.target.result);
      digestOp.onerror = (e: ErrorEvent) => reject(e.error);
      digestOp.onabort = () => reject(new Error('Digest aborted'));
    });
  }

  return digestOp;
};

const urlEncodeB64 = (input: string) => {
  const b64Chars: { [index: string]: string } = { '+': '-', '/': '_', '=': '' };
  return input.replace(/[+/=]/g, (m: string) => b64Chars[m]);
};

const bufferToBase64UrlEncoded = (input: number[] | Uint8Array) => {
  const ie11SafeInput = new Uint8Array(input);
  return urlEncodeB64(
    window.btoa(String.fromCharCode(...Array.from(ie11SafeInput)))
  );
};

const getAuthToken = async (code: string) => {
  let storage;
  try {
    storage = JSON.parse(localStorage.getItem(AUTH_STORAGE_KEY)!);
  } catch (err) {
    log(`Invalid storage key / storage corrupted: ${err}`);
    throw new Error(`Invalid storage key / storage corrupted: ${err}`);
  } finally {
    // Any local storage item is only used once
    localStorage.removeItem(AUTH_STORAGE_KEY);
    window.history.replaceState(null, document.title, window.location.pathname);
  }

  if (!storage || !storage.verifier) throw new Error('Invalid storage');
  return getToken({ code, verifier: storage.verifier });
};

export const refreshTokenIfNeeded = async (token: Token) => {
  const { logout } = useAuthStore.getState();
  const now = Math.floor(Date.now() / 1000);
  // Return the current token if expiry date is fine
  if (token.expires_at - TOKEN_EXPIRY_ADJUSTMENT > now) return token;

  // Otherwise refresh the token then return it
  try {
    const newToken = await refreshToken(token.refresh_token);
    useAuthStore.getState().setToken(newToken);
    return newToken;
  } catch (err) {
    log(`Could not refresh the token: ${err}`);
    // Redirect to sign in if we had an error
    // while refreshing token, much like on initial login
    logout();
    throw new Error(`Could not refresh the token: ${err}`);
  }
};

export const authenticateWithRedirect = async (loginHint?: string) => {
  // First we clear the store saved to storage
  // otherwise we will go into infinite redirect to login
  // because of token being invalid
  useAuthStore.options.storage.removeItem(getKey(useAuthStore.options.name));

  const authenticatedAugmentationFlag = TEAMS_FEATURE_FLAGS.includes(
    'authenticatedAugmentation'
  );
  const verifier = createRandomString();
  const codeChallengeBuffer = await sha256(verifier);
  const codeChallenge = bufferToBase64UrlEncoded(codeChallengeBuffer);
  const searchParams = {
    response_type: AUTH.responseType,
    response_mode: AUTH.responseMode,
    nonce: createRandomString(),
    state: createRandomString(),
    code_challenge: codeChallenge,
    code_challenge_method: 'S256',
    client_id: AUTH.clientId,
    scope: AUTH.scope,
    redirect_uri: AUTH.redirectUri,
    ...(authenticatedAugmentationFlag && IS_MICROSOFT_TEAMS && { idp: IDP_ID }),
    ...(authenticatedAugmentationFlag && loginHint && { login_hint: loginHint })
  };

  // Save auth params so we can reuse them to grab the auth token
  localStorage.setItem(
    AUTH_STORAGE_KEY,
    JSON.stringify({ ...searchParams, verifier })
  );

  const urlSearchParams = new URLSearchParams(searchParams);
  const authorizeUrl = new URL(AUTH.loginUrl);
  authorizeUrl.searchParams.forEach((v, k) => urlSearchParams.append(k, v));
  authorizeUrl.search = `?${urlSearchParams}`;
  window.location.href = authorizeUrl.toString();
};

export const setupAuth = async (
  code?: string,
  throwError?: boolean,
  authError?: string,
  authErrorDescription?: string
) => {
  let error = authError;

  const { token, setToken, logout } = useAuthStore.getState();
  const { setUser, setOnboarding } = useUserStore.getState();

  let currentToken = token;

  try {
    // Get the token since we just got redirected from the login
    if (code) {
      currentToken = await getAuthToken(code);
      setToken(currentToken);
    }

    // Always get/refresh the current user
    if (currentToken) {
      // If we didn't get redirected the tracking of login has not yet been initilized
      if (!code) trackLoginBegin();

      const user = await getCurrentUser();
      setUser(user);

      trackLoginComplete();

      /**
       * Get onboarding data, but silently ignore errors to not trigger a relogin.
       * We don't care if it fails here as it not vital data,
       * and if no onboarding data is available it will return 404.
       */
      try {
        const onboarding = await getOnboarding();
        setOnboarding(onboarding);
      } catch (err) {
        log(`Onboarding silent error: ${err}`);
      }
    }
  } catch (err) {
    typeof err === 'string' && (error = err);
  }

  if (error) {
    log(`Login error: ${authErrorDescription || error}`);
    // Redirect to sign in if we had an error
    // while getting initial user or the token
    // TODO: handle redirect params
    logout();

    if (throwError) {
      throw Error(authErrorDescription || error);
    }
  }
  return currentToken;
};

export const setupWebAuth = async () => {
  const searchParams = new URLSearchParams(window.location.hash.substring(1));
  // TODO: Validate state
  const {
    code,
    error: authError,
    error_description: authErrorDescription
  } = Object.fromEntries(searchParams);

  return setupAuth(code, false, authError, authErrorDescription);
};
