import { debounce } from 'lodash';
import create from 'zustand';
import {
  combine,
  StoreApiWithSubscribeWithSelector,
  subscribeWithSelector
} from 'zustand/middleware';
import {
  GetState,
  SetState,
  State,
  StateCreator,
  StoreApi
} from 'zustand/vanilla';

type Options<S> = {
  name: string;
  whitelist?: (keyof S)[];
  blacklist?: (keyof S)[];
  storage: Storage;
  debounced: boolean;
};

const KEY_PREFIX = 'postitteams';

export const getKey = (name: string) => `${KEY_PREFIX}-${name}`;

// Simplified persist middleware inspired from the official
// persist middleware https://github.com/pmndrs/zustand/blob/master/src/middleware.ts
export const persist =
  <S extends State>(config: StateCreator<S>, options: Options<any>) =>
  (set: SetState<S>, get: GetState<S>, api: StoreApi<S>): S => {
    const { name, whitelist, blacklist, storage, debounced } = options;

    const setItem = async () => {
      const state = { ...get() };

      if (whitelist) {
        (Object.keys(state) as (keyof S)[]).forEach(key => {
          !whitelist.includes(key) && delete state[key];
        });
      }

      if (blacklist) {
        blacklist.forEach(key => delete (state as any)[key]);
      }

      return storage!.setItem(name, await JSON.stringify(state));
    };

    const wrappedSetItem = debounced ? debounce(setItem, 300) : setItem;

    const savedSetState = api.setState;

    // eslint-disable-next-line no-param-reassign
    api.setState = (state, replace) => {
      savedSetState(state, replace);
      void wrappedSetItem();
    };

    // Add options so we can read it later
    (api as any).options = options;

    return config(
      (...args) => {
        set(...args);
        void wrappedSetItem();
      },
      get,
      api
    );
  };

// Helper for creating a store with
// local storage state and combine
export const createPersistedStore = <
  PrimaryState extends State,
  SecondaryState extends State = State
>(
  defaultValues: PrimaryState,
  set: (
    set: SetState<PrimaryState>,
    get: GetState<PrimaryState>,
    api: StoreApi<PrimaryState>
  ) => SecondaryState,
  {
    name,
    storage = localStorage,
    debounced = false,
    ...options
  }: WithOptional<Options<PrimaryState>, 'storage' | 'debounced'>
) => {
  const key = getKey(name);

  let defaultState: PrimaryState;

  try {
    const item = storage.getItem(key);
    // We merge default values and stored values
    if (item) defaultState = { ...defaultValues, ...JSON.parse(item) };
    else throw new Error();
  } catch {
    defaultState = defaultValues;
  }

  type CombinedState = Combine<PrimaryState, SecondaryState>;
  const store = create<
    CombinedState,
    SetState<CombinedState>,
    GetState<CombinedState>,
    StoreApiWithSubscribeWithSelector<CombinedState>
  >(
    subscribeWithSelector(
      persist(combine(defaultState, set), {
        ...options,
        storage,
        name: key,
        debounced
      })
    )
  );

  return store as typeof store & {
    options: Options<PrimaryState>;
  };
};
