import React from 'react';
import { Link } from 'react-router-dom';

export const NotFound = () => (
  <div className="d-flex align-items-center justify-content-center vh-100">
    <div className="p-6 border t-center">
      <div>You are lost...</div>
      <Link to="/">Take me home</Link> 🛸
    </div>
  </div>
);
