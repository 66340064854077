import { Configuration, LogLevel } from '@azure/msal-browser';

import { FileType } from '../types/explore';

export const AUGMENTATION_URL = process.env.AUGMENTATION_URL!;
export const IDP_ID = process.env.IDP_ID!;
export const TEAMS_FEATURE_FLAGS: string[] =
  process.env.TEAMS_FEATURE_FLAGS?.split(', ') ?? [];
export const MICROSOFT_TEAMS_NAME = process.env.MICROSOFT_TEAMS_NAME!;
export const MICROSOFT_AUTH = {
  redirectUri: process.env.AUTH_MSFT_REDIRECT_URL!,
  scopes: ['email', 'openid', 'profile', 'offline_access'],
  clientId: process.env.AUTH_MSFT_CLIENT_ID!
};

export const MSAL_CONFIG: Configuration = {
  auth: {
    clientId: MICROSOFT_AUTH.clientId,
    redirectUri: MICROSOFT_AUTH.redirectUri,
    postLogoutRedirectUri: '/'
  },
  cache: {
    cacheLocation: 'sessionStorage'
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        import('../../../src/utils/generic').then(({ createLogger }) => {
          const logger = createLogger('msal-log');
          if (containsPii) return;
          switch (level) {
            /** Uncomment to debug msal auth. Clogs the console, so commented by default */
            // case LogLevel.Info:
            //   logger.log(message);
            //   return;
            // case LogLevel.Verbose:
            //   logger.log(message);
            //   return;
            case LogLevel.Error:
              logger.error(message);
              return;
            case LogLevel.Warning:
              logger.warn(message);
            // eslint-disable-next-line no-fallthrough
            default:
          }
        });
      }
    }
  }
};

export const FILE_EXTENSION_MAPPING: Record<string, FileType> = {
  one: 'OneNote',
  pdf: 'Pdf',
  doc: 'Word',
  docx: 'Word',
  xls: 'Excel',
  xlsx: 'Excel',
  xlsm: 'Excel',
  xlsb: 'Excel',
  pptx: 'PowerPoint',
  ppt: 'PowerPoint'
};
