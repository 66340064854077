import React from 'react';
import { Navigate } from 'react-router-dom';

import { Spinner } from '../../../../src/components/core/Spinner/Spinner';
import { useGetBoardBySessionId } from '../../apis/meetings';

interface Props {
  sessionId: string;
}

export const StartConfiguredBoard = ({ sessionId }: Props) => {
  const { isError, data: board } = useGetBoardBySessionId(sessionId);

  return isError ? (
    <span>
      Could not find the board, please associate a board with this meeting
    </span>
  ) : board?.id ? (
    <Navigate to={`/board/${board.id}?configurable=true`} replace />
  ) : (
    <>
      <Spinner className="mr-2" size="lg" />
      <span>Connecting to board...</span>
    </>
  );
};
