import axios from 'axios';
import { object, string } from 'yup';

import { AUTH } from '../../utils/constants';
import { validationWrapper } from '../utils.schema';
import { GRANT_TYPES, tokenSchema } from './auth.schema';

export const getToken = validationWrapper({
  fn: async ({ code, verifier }) =>
    (
      await axios.post(
        AUTH.tokenUrl,
        new URLSearchParams({
          client_id: AUTH.clientId,
          grant_type: AUTH.grantType,
          scope: AUTH.scope,
          code,
          code_verifier: verifier,
          redirect_uri: AUTH.redirectUri
        }).toString(),
        { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } }
      )
    ).data,
  payloadSchema: object({
    code: string().required(),
    verifier: string().required()
  }),
  responseSchema: tokenSchema
});

export const refreshToken = validationWrapper({
  fn: async refresh_token =>
    (
      await axios.post(
        AUTH.tokenUrl,
        new URLSearchParams({
          client_id: AUTH.clientId,
          grant_type: GRANT_TYPES.refresh_token,
          refresh_token
        }).toString(),
        { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } }
      )
    ).data,
  payloadSchema: string().required(),
  responseSchema: tokenSchema
});
