import { Box, Flex, Header, Loader, Text } from '@fluentui/react-northstar';
import * as microsoftTeams from '@microsoft/teams-js';
import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { useEffectOnce } from 'react-use';

import { SadFaceIcon } from '../../../../src/components/core/Icons/Icons';
import { Modal } from '../../../../src/components/core/Modal/Modal';
import { trackLoginBegin } from '../../../../src/services/analytics';
import { AUTH } from '../../../../src/utils/constants';
import { createLogger } from '../../../../src/utils/generic';
import { setupTeamsAuth } from '../../services/auth';
import './Auth.scss';

const logger = createLogger('auth-start');

interface ErrorTexts {
  title: string;
  description: string;
}

const authError = {
  title: 'It seems you don’t have the access to Post-it® App.',
  description:
    'Ask to your System Administrator for access or try to login again'
};

const generalError = {
  title: 'An error occured',
  description: 'Check your internet connection or try to re-start Teams app'
};

const AuthStart = () => {
  const [authSuccess, setAuthSuccess] = useState(false);
  const [loginError, setLoginError] = useState<ErrorTexts>();
  const [to, setTo] = useState('/');
  const searchParams = new URLSearchParams(window.location.search);
  const { from } = Object.fromEntries(searchParams);

  useEffectOnce(() => {
    microsoftTeams.initialize(() => {
      trackLoginBegin(); // This is only called if the user needs to log in after clearing cookies or similar
      const authBaseUrl = AUTH.redirectUri.split('/login/callback')[0];
      microsoftTeams.getContext(teamsContext => {
        microsoftTeams.authentication.authenticate({
          url: `${authBaseUrl}/auth-update`,
          width: 500,
          height: 500,
          successCallback: async (code?: string) => {
            if (!code) {
              logger.warn('No auth code given');
              setLoginError(generalError);
              return;
            }

            try {
              await setupTeamsAuth(teamsContext.loginHint, code);
              setAuthSuccess(true);
            } catch (error: any) {
              logger.error(error);
              setLoginError(authError);
            }
          },
          failureCallback: (error: any) => {
            logger.error(error);
            setLoginError(generalError);
          }
        });
      });
    });
  });

  useEffect(() => {
    if (!from) return;
    setTo(from);
  }, [from]);

  return (
    <Box className="auth-teams">
      {authSuccess ? <Navigate to={to} /> : <Loader label="Logging in..." />}
      <Modal position="center" closable={false} open>
        <Flex className="t-center px-5" vAlign="center" column>
          <SadFaceIcon fontSize="95px" width="100%" />
          <Header className="mt-6 t-lg" as="h1">
            {loginError?.title}
          </Header>
          <Text className="mt-5 t-gray-700">{loginError?.description}</Text>
        </Flex>
      </Modal>
    </Box>
  );
};

// eslint-disable-next-line import/no-default-export
export default AuthStart;
