import { InferType, object, string } from 'yup';

import { personNameSchema } from '../team/team.schema';

export const userSchema = object({
  id: string().required(),
  profile: object({
    email: string().email().required(),
    name: personNameSchema
  })
});
export type User = InferType<typeof userSchema>;
