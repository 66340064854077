import { Box, Loader } from '@fluentui/react-northstar';
import * as microsoftTeams from '@microsoft/teams-js';
import React from 'react';
import { useEffectOnce } from 'react-use';

import { AUTH_STORAGE_KEY } from '../../../../src/utils/constants';

import '../auth-start/Auth.scss';

// Component called on auth redirect.
const AuthRedirect = () => {
  useEffectOnce(() => {
    microsoftTeams.initialize(() => {
      const search = new URLSearchParams(window.location.hash.substring(1));
      const code = search.get('code');
      const state = search.get('state');

      let storage;

      try {
        storage = JSON.parse(localStorage.getItem(AUTH_STORAGE_KEY)!);
      } catch (err) {
        microsoftTeams.authentication.notifyFailure(
          `Invalid storage key / storage corrupted: ${err}`
        );
      }

      if (!storage || !storage.state) {
        microsoftTeams.authentication.notifyFailure('Invalid storage');
      } else if (!state === storage.state) {
        microsoftTeams.authentication.notifyFailure('Invalid state');
      } else if (code) {
        microsoftTeams.authentication.notifySuccess(code!);
      } else {
        microsoftTeams.authentication.notifyFailure(
          `${search.get('error')}: ${search.get('error_description')}`
        );
      }
    });
  });

  return (
    <Box className="auth-teams">
      <Loader label="Redirecting back..." />
    </Box>
  );
};

// eslint-disable-next-line import/no-default-export
export default AuthRedirect;
