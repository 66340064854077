import create from 'zustand';

type TeamsAuthState = {
  hasError: boolean;
  isAuthenticated: boolean;
};

type TeamsAuthStore = TeamsAuthState & {
  setHasError: (hasError: boolean) => void;
  setIsAuthenticated: (isAuthenticated: boolean) => void;
};

const defaultState: TeamsAuthState = {
  hasError: false,
  isAuthenticated: false
};

export const useTeamsAuthStore = create<TeamsAuthStore>(set => ({
  ...defaultState,
  setHasError: (hasError: boolean) => set({ hasError }),
  setIsAuthenticated: (isAuthenticated: boolean) => set({ isAuthenticated })
}));
