/* eslint-disable no-console */

import { CanvasElement } from '../canvas/types';
import { SizeClass } from '../types/core.schema';
import {
  INCH_TO_MM,
  IS_PRODUCTION,
  NoteShape,
  NoteSize,
  NOTE_COLOR_LABELS,
  NOTE_SHAPE,
  NOTE_SIZE_CLASSES_SIZE_AND_SHAPE
} from './constants';

const colors = [
  '#f6685e',
  '#ed4b82',
  '#af52bf',
  '#8561c5',
  '#6573c3',
  '#4dabf5',
  '#35baf6',
  '#33c9dc',
  '#33ab9f',
  '#6fbf73',
  '#a2cf6e',
  '#d7e360',
  '#ffef62',
  '#ffcd38',
  '#ffac33',
  '#ff784e'
];

const parseArgs = (args: unknown[]) =>
  args.map(arg => {
    if (typeof arg === 'string') {
      try {
        return JSON.parse(arg);
      } catch {
        /* empty */
      }
    }
    return arg;
  });

export const createLogger = (prefix: string) => {
  const color = colors.splice(0, 1);
  return {
    log: (tag: string, ...args: unknown[]) => {
      if (IS_PRODUCTION) return;
      const outputs = parseArgs(args);
      console.groupCollapsed(
        `%c[${prefix}] ${tag}`,
        `color: ${color}`,
        ...outputs
      );
      console.trace();
      console.groupEnd();
    },
    warn: (tag: string, ...args: unknown[]) => {
      const outputs = parseArgs(args);
      console.warn(`[${prefix}] ${tag}`, ...outputs);
    },
    error: (tag: string, ...args: unknown[]) => {
      const outputs = parseArgs(args);
      console.error(`[${prefix}] ${tag}`, ...outputs);
    }
  };
};

export const DOMLog = (...args: any) => {
  if (IS_PRODUCTION) return;
  let div = document.getElementById('__domlog');
  if (!div) {
    div = document.createElement('div');
    div.id = '__domlog';
  }
  div.innerHTML = args.join(' ');
  div.style.position = 'fixed';
  div.style.top = '0';
  div.style.left = '0';
  div.style.backgroundColor = 'white';
  document.body.appendChild(div);
};

export const filterValueBy = (value: string, needle: string) =>
  !needle || value.toLowerCase().includes(needle.toLowerCase());

// It is actually an inches => millimeters
// but since for the web 1mm = 1px we are good to go
export const inchesSizeToMmSize = (sizeClass: SizeClass) => {
  const [width, height] = sizeClass.split('x').map(Number);
  return {
    width: width * INCH_TO_MM,
    height: height * INCH_TO_MM
  };
};

export const getSizeAndShape = <T extends SizeClass | undefined>(
  sizeClass: T
) =>
  (NOTE_SIZE_CLASSES_SIZE_AND_SHAPE[
    sizeClass as keyof typeof NOTE_SIZE_CLASSES_SIZE_AND_SHAPE
  ] ?? [
    undefined,
    undefined
  ]) as T extends keyof typeof NOTE_SIZE_CLASSES_SIZE_AND_SHAPE
    ? typeof NOTE_SIZE_CLASSES_SIZE_AND_SHAPE[T]
    :
        | typeof NOTE_SIZE_CLASSES_SIZE_AND_SHAPE[keyof typeof NOTE_SIZE_CLASSES_SIZE_AND_SHAPE]
        | [undefined, undefined];

export const getSizeClass = (
  shape: NoteShape | undefined,
  size: NoteSize | undefined
) =>
  Object.entries(NOTE_SIZE_CLASSES_SIZE_AND_SHAPE).find(
    ([, value]) => value[0] === (size ?? 's') && value[1] === (shape ?? 'sqr')
  )?.[0] as SizeClass | undefined;

export const getShape = <T extends string>(sizeClass: T) => {
  const [width, height] = sizeClass.split('x').map(Number);
  return (
    (width > height && NOTE_SHAPE.HOR) ||
    (width < height && NOTE_SHAPE.VER) ||
    NOTE_SHAPE.SQR
  );
};
export const getColorName = (hex: string) =>
  hex === 'mixed'
    ? 'Multiple colors'
    : NOTE_COLOR_LABELS[hex] || 'Captured color';

export const sortByDateAsc = (key?: string) => (a: any, b: any) =>
  new Date(key ? a[key] : a).getTime() - new Date(key ? b[key] : b).getTime();

export const sortByDateDesc = (key?: string) => (a: any, b: any) =>
  new Date(key ? b[key] : b).getTime() - new Date(key ? a[key] : a).getTime();

export const sortByAlphaAsc = (key?: string) => (a: any, b: any) =>
  (key ? a[key] : a).localeCompare(key ? b[key] : b);

export const sortByAlphaDesc = (key?: string) => (a: any, b: any) =>
  (key ? a[key] : a).localeCompare(key ? b[key] : b) * -1;

export const sortByAscFractionalIndexes = <T extends CanvasElement>(
  elements: T[]
) =>
  [...elements].sort((a, b) =>
    a.placement.zIndex.compareTo(b.placement.zIndex)
  );

export const sortByDescFractionalIndexes = <T extends CanvasElement>(
  elements: T[]
) => sortByAscFractionalIndexes(elements).reverse();

export const randomSign = () => Math.sign(Math.random() - 0.5);

/**
 * Fallback function to copy without the clipboard API.
 * Instead using the deprecated execCommand to copy.
 */
export const fallbackCopyTextToClipboard = (text: string) => {
  const textArea = document.createElement('textarea');
  textArea.value = text;

  // Avoid scrolling to bottom
  textArea.style.top = '0';
  textArea.style.left = '0';
  textArea.style.position = 'fixed';

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    const successful = document.execCommand('copy');

    if (!successful) throw Error('Unsuccessful');
  } catch (e) {
    /** empty */
  }

  document.body.removeChild(textArea);
};
