import {
  AuthenticationResult,
  EventType,
  PublicClientApplication
} from '@azure/msal-browser';

import { setupAuth } from '../../../src/services/auth';
import { useAuthStore } from '../../../src/stores/AuthStore/useAuthStore';
import { useUserStore } from '../../../src/stores/UserStore/useUserStore';
import { createLogger } from '../../../src/utils/generic';
import { useTeamsAuthStore } from '../stores/useTeamsAuthStore';
import { MICROSOFT_AUTH, MSAL_CONFIG } from '../utils/constants';

const logger = createLogger('teams-auth');

/** Microsoft authentication setup */
export const msalInstance = new PublicClientApplication(MSAL_CONFIG);

if (
  !msalInstance.getActiveAccount() &&
  msalInstance.getAllAccounts().length > 0
) {
  msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
}
/** Enable this instance to update when a user logs in or out of the app in a different window/tab */
msalInstance.enableAccountStorageEvents();
msalInstance.addEventCallback(event => {
  logger.log('Received msal event', event.eventType);
  if (event.eventType !== EventType.LOGIN_SUCCESS) return;

  const payload = event.payload as AuthenticationResult;
  if (payload.account) msalInstance.setActiveAccount(payload.account);
});

const ssoSilent = async (loginHint?: string) => {
  const request = {
    scopes: MICROSOFT_AUTH.scopes,
    loginHint
  };

  try {
    const ssoReq = await msalInstance.ssoSilent(request);
    useTeamsAuthStore.setState({ isAuthenticated: true });
    return ssoReq;
  } catch (err) {
    logger.error(`Silent single sign on request could not be fulfilled`);
    useTeamsAuthStore.setState({ isAuthenticated: false });
  }
};

export const setupTeamsAuth = async (loginHint?: string, code?: string) => {
  const { logout } = useAuthStore.getState();
  const { user } = useUserStore.getState();

  if (loginHint !== user.profile.email) logout();

  try {
    await ssoSilent(loginHint);
    const auth = await setupAuth(code, true);
    return auth;
  } catch (err) {
    logger.log(`Login failure: ${err}`);
    throw Error(`${err}`);
  }
};
