/* eslint-disable no-console */
/* eslint-disable no-template-curly-in-string */

import { AxiosError } from 'axios';
import {
  AnySchema,
  ArraySchema,
  InferType,
  mixed,
  MixedSchema,
  ObjectSchema
} from 'yup';

import { IS_PRODUCTION } from '../utils/constants';

const tabs = (n: number) => Array(n).fill('\t').join('');
export const describeSchema = (schema: AnySchema, lvl = 1) => {
  if (!(schema instanceof MixedSchema)) return '';
  let result = '';
  if (schema instanceof ObjectSchema) {
    result += '({\n';
    result += Object.entries(schema.fields)
      .map(
        ([k, v]: [string, any]) =>
          `${tabs(lvl)}${k}${
            v.spec.presence === 'optional' ? '?' : ''
          }: ${describeSchema(v as AnySchema, lvl + 1)}`
      )
      .join('\n ');
    result += `\n${tabs(lvl - 1)}}`;
  } else if (schema instanceof ArraySchema) {
    result += `(${describeSchema(schema.innerType as AnySchema, lvl)}[]`;
  } else {
    result += schema.type;
  }
  return (result += [
    schema.spec.presence !== 'required' && ' | undefined',
    schema.spec.nullable && ' | null',
    ['object', 'array'].includes(schema.type) && ')'
  ]
    .filter(Boolean)
    .join(''));
};

export const validationWrapper =
  <T, U extends Promise<any>, V extends AnySchema, W extends AnySchema>({
    fn,
    payloadSchema,
    responseSchema,
    strict
  }: {
    fn: (arg: typeof payloadSchema extends undefined ? T : InferType<V>) => U;
    payloadSchema?: V;
    responseSchema?: W;
    strict?: boolean;
  }) =>
  async (arg: typeof payloadSchema extends undefined ? T : InferType<V>) => {
    payloadSchema &&=
      payloadSchema instanceof ObjectSchema
        ? payloadSchema.noUnknown()
        : payloadSchema;

    try {
      payloadSchema && (arg = await payloadSchema.validate(arg));
    } catch (error) {
      if (strict) throw error;
      console.error(error);
      console.log(
        'Expected payload: \n',
        describeSchema(payloadSchema!),
        '\n\nReceived payload: ',
        JSON.stringify(arg, null, 2)
      );
    }

    let res = await fn(arg);

    try {
      responseSchema && (res = await responseSchema.validate(res));
    } catch (error) {
      if (strict) throw error;
      console.error(error);
      console.log(
        'Expected response: \n',
        describeSchema(responseSchema!),
        '\n\nReceived response: \n',
        JSON.stringify(res, null, 2)
      );
    }

    return res as typeof responseSchema extends undefined ? U : InferType<W>;
  };

export const cast = <T extends AnySchema, U>(
  schema: T,
  value: U,
  strict = !IS_PRODUCTION
) => {
  try {
    return schema.validateSync(value, {
      stripUnknown: true,
      abortEarly: true
    }) as InferType<T>;
  } catch (error) {
    console.error(error);
    console.log(
      'Expected value: \n',
      describeSchema(schema!),
      '\n\nReceived value: \n',
      JSON.stringify(value, null, 2)
    );
    if (strict) throw error;
    return value as InferType<T>;
  }
};

export const axiosErrorSchema = mixed<AxiosError>()
  .test({
    name: 'AxiosError',
    message: '${path} must implement `AxiosError`',
    test: value => !!value?.isAxiosError
  })
  .required();
