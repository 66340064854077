import React from 'react';

import { useAuthStore } from '../../../stores/AuthStore/useAuthStore';
import { SadFaceIcon } from '../../core/Icons/Icons';
import { Modal } from '../../core/Modal/Modal';

export const ApiVersionErrorModal = () => {
  const { apiVersionTooOld } = useAuthStore();

  return (
    <Modal centered closable={false} open={apiVersionTooOld}>
      <div className="d-flex flex-column">
        <SadFaceIcon fontSize="95px" width="100%" />
        <p className="t-center mt-5">This application is out of date.</p>
      </div>
    </Modal>
  );
};
