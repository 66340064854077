import clsx from 'clsx';
import React, {
  CSSProperties,
  KeyboardEvent,
  SyntheticEvent,
  useCallback,
  useEffect,
  useRef,
  forwardRef
} from 'react';
import { createPortal } from 'react-dom';

import { KEYS } from '../../../utils/constants';
import { Button } from '../Button/Button';
import { CrossIcon } from '../Icons/Icons';

type ModalSizes = 'xs' | 'sm' | 'md' | 'lg' | 'xl';
export interface ModalProps
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  open?: boolean;
  onClose?: (event: SyntheticEvent) => void;
  closable?: boolean;
  closeOnClick?: boolean;
  transparent?: boolean;
  centered?: boolean;
  invertedColors?: boolean;
  roundedCorners?: boolean;
  size?: ModalSizes;
  position?: 'default' | 'top' | 'center' | 'bottom';
  contentClassName?: string;
  contentStyle?: CSSProperties;
}

export const Modal = forwardRef<HTMLDivElement, ModalProps>(
  (
    {
      closable = true,
      closeOnClick = true,
      transparent,
      centered,
      className,
      children,
      onClose,
      open = true,
      invertedColors,
      roundedCorners,
      size = 'md',
      position = 'default',
      contentClassName,
      contentStyle,
      ...rest
    },
    ref
  ) => {
    const dialog = useRef<HTMLDivElement>(null);
    const lastFocus = useRef<HTMLElement | null>();

    const onBackdropClick = (event: SyntheticEvent) => {
      if (closable && closeOnClick && onClose) onClose(event);
    };

    const onKeyUp = (event: KeyboardEvent) => {
      if (!closable || event.key !== KEYS.ESCAPE) return;
      if (onClose) onClose(event);
    };

    const focusDialog = useCallback(() => {
      if (!dialog.current) return;

      const focus = document.activeElement as HTMLElement;
      const isDialogFocused =
        focus === dialog.current || dialog.current.contains(focus);

      if (!isDialogFocused) {
        lastFocus.current = focus;
        dialog.current.focus();
      }
    }, []);

    const restoreFocus = useCallback(() => {
      lastFocus.current?.focus();
      lastFocus.current = null;
    }, []);

    useEffect(() => {
      if (!window.location.pathname.startsWith('/board/'))
        document.body.style.overflow = open ? 'hidden' : '';
      if (open) {
        focusDialog();
        document.addEventListener('focus', focusDialog, true);
      } else {
        document.removeEventListener('focus', focusDialog, true);
        restoreFocus();
      }
    }, [open, focusDialog, restoreFocus]);

    // Cleanup effect
    useEffect(
      () => () => {
        restoreFocus();
        document.body.style.overflow = '';
        document.removeEventListener('focus', focusDialog, true);
      },
      [restoreFocus, focusDialog]
    );

    return open
      ? createPortal(
          <div
            className={clsx('modal', className)}
            role="dialog"
            {...rest}
            onKeyDown={e => e.stopPropagation()}
            onPaste={e => e.stopPropagation()}
            ref={ref}
          >
            <div
              className={clsx(
                'modal__backdrop',
                invertedColors && 'modal__backdrop--inverted'
              )}
              aria-hidden
              onClick={onBackdropClick}
            />
            <div
              className={clsx(
                'modal__dialog',
                `modal__dialog--${position}`,
                size && `modal__dialog--${size}`
              )}
              role="document"
            >
              <div
                className={clsx(
                  'modal__content',
                  transparent && 'modal__content--transparent',
                  invertedColors && 'modal__content--inverted',
                  roundedCorners && 'modal__content--rounded',
                  contentClassName
                )}
                style={contentStyle}
                ref={dialog}
                role="presentation"
                onKeyUp={onKeyUp}
                tabIndex={-1}
              >
                {closable && (
                  <Button
                    variant="naked"
                    className="modal__close"
                    aria-label="Close"
                    onClick={onClose}
                  >
                    <CrossIcon />
                  </Button>
                )}
                {children}
              </div>
            </div>
          </div>,
          document.body
        )
      : null;
  }
);

Modal.displayName = 'Modal';
