import * as Sentry from '@sentry/react';

import {
  ENVIRONMENT,
  IS_LOCALHOST,
  SENTRY_DSN,
  VERSION
} from '../utils/constants';

export const setupSentry = () =>
  !IS_LOCALHOST &&
  SENTRY_DSN &&
  Sentry.init({
    dsn: SENTRY_DSN,
    release: VERSION,
    environment: ENVIRONMENT,
    // TODO: Change this for production code when we go live
    // otherwise we will explode our quota
    tracesSampleRate: 1.0
  });
