import {
  sortByAlphaAsc,
  sortByAlphaDesc,
  sortByDateAsc,
  sortByDateDesc
} from '../../utils/generic';
import { createPersistedStore } from '../utils';

export const sortBoardFns = {
  'title-asc': sortByAlphaAsc('title'),
  'title-desc': sortByAlphaDesc('title'),
  'date-asc': sortByDateAsc('createdAt'),
  'date-desc': sortByDateDesc('createdAt')
};

type BoardMode = 'idle' | 'select';
export type BoardView = 'grid' | 'list';
export type BoardSortBy = keyof typeof sortBoardFns;
export type BoardsStoreState = typeof defaultState;

const defaultState = {
  view: 'grid' as BoardView,
  mode: 'idle' as BoardMode,
  search: '',
  sortBy: 'date-desc' as BoardSortBy,
  filterBy: '',
  selection: {} as Record<string, boolean>
};

export const useBoardsStore = createPersistedStore(
  defaultState,
  set => ({
    reset: () => set(defaultState),
    setMode: (mode: BoardMode) => set({ mode, selection: {} }),
    setView: (view: BoardView) => set({ view }),
    setSearch: (search: string) => set({ search }),
    setSortBy: (sortBy: BoardSortBy) => set({ sortBy }),
    setFilterBy: (filterBy: string) => set({ filterBy }),
    deselectAll: () => set({ selection: {} }),
    select: (id: string, selected: boolean) =>
      set(state => ({ selection: { ...state.selection, [id]: selected } })),
    selectMultiple: (ids: string[]) =>
      set(state => ({
        selection: {
          ...state.selection,
          ...Object.fromEntries(ids.map(id => [id, true]))
        }
      }))
  }),
  {
    name: 'boards',
    storage: sessionStorage,
    whitelist: ['view', 'sortBy', 'filterBy']
  }
);
