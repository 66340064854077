import { Scene } from '../canvas/Scene';
import { NoteElement } from '../canvas/types';
import {
  TaskExportMethod,
  TrackEventType,
  TrackGroupCreateMethod,
  TrackNoteCreateMethod,
  TrackNoteDuplicateMethod,
  TrackNoteEditedUpdate,
  TrackNoteEvent,
  TrackSearchCompleteMethod,
  TrackStackCreateMethod
} from '../types/analytics';
import { SizeClass } from '../types/core.schema';
import {
  DEBUG,
  DEBUG_MODES,
  IS_DEBUG,
  NOTE_SIZE_CLASSES_SIZE_AND_SHAPE,
  TRACKING_COLORS,
  TRACKING_NOTE_SHAPES,
  TRACKING_NOTE_SIZES
} from '../utils/constants';
import { createLogger, getShape } from '../utils/generic';

const { log } = createLogger('analytics');

const getSizeClassToParams = (sizeClass: SizeClass) =>
  NOTE_SIZE_CLASSES_SIZE_AND_SHAPE[
    sizeClass as keyof typeof NOTE_SIZE_CLASSES_SIZE_AND_SHAPE
  ] || ['captured', getShape(sizeClass)];

const getTrackNoteEvent = (
  note: NoteElement,
  boardNoteCount: number
): TrackNoteEvent => {
  const [size, shape] = getSizeClassToParams(note.sizeClass);
  return {
    note_color: TRACKING_COLORS[note.color],
    note_size: TRACKING_NOTE_SIZES[size] ?? 'captured',
    note_aspect_ratio: TRACKING_NOTE_SHAPES[shape],
    board_note_count: boardNoteCount
  };
};

const trackEvent = <T extends Record<string, unknown>>(
  eventType: TrackEventType,
  details: T
) => {
  if (!IS_DEBUG) return;
  DEBUG.MODE === DEBUG_MODES.ANALYTICS && log(`Track ${eventType}`, details);
  window.dataLayer ||= [];
  window.dataLayer.push({ event: eventType, ...details });
};

/* Board tracking events */

export const trackBoardViewed = (
  boardNoteCount: number,
  groupCount: number
) => {
  trackEvent('page_view', {
    dl_page_title: 'board_view',
    board_note_count: boardNoteCount,
    group_count: groupCount
  });
};

export const trackBoardCreate = () => {
  trackEvent('board_create', {});
};

const getBoardMethod = () =>
  window.location.pathname.includes('/boards') ? 'boards' : 'board';

export const trackBoardDelete = () => {
  trackEvent('board_delete', { method: getBoardMethod() });
};

export const trackBoardRename = () => {
  trackEvent('board_rename', { method: getBoardMethod() });
};

export const trackBoardDuplicate = () => {
  trackEvent('board_duplicate', { method: getBoardMethod() });
};

export const trackDashboardViewed = (boardCount: number) => {
  trackEvent('page_view', {
    dl_page_title: 'dashboard',
    board_count: boardCount
  });
};

/* Note tracking events */

export const trackNoteCreated = (
  note: NoteElement,
  boardNoteCount: number,
  method: TrackNoteCreateMethod
) =>
  trackEvent('note_create', {
    ...getTrackNoteEvent(note, boardNoteCount),
    method
  });

export const trackNoteSelected = () => trackEvent('note_select', {});

export const trackNoteDuplicated = (
  noteCount: number,
  boardNoteCount: number,
  method: TrackNoteDuplicateMethod
) =>
  trackEvent('note_duplicate', {
    note_count: noteCount,
    board_note_count: boardNoteCount,
    method
  });

export const trackNoteDeleted = (boardNoteCount: number, noteCount: number) =>
  trackEvent('note_delete', {
    board_note_count: boardNoteCount,
    note_count: noteCount
  });

export const trackNoteEdited = (
  scene: Scene,
  updates: TrackNoteEditedUpdate[]
) => {
  const aggregatedEditedValues: Record<string, string[]> = {};

  updates.forEach(({ note, update }) => {
    if (!aggregatedEditedValues[note.id]) aggregatedEditedValues[note.id] = [];

    const editedValue = [];

    if (update.color) {
      editedValue.push('color');
    }

    if (update.sizeClass) {
      const [oldSize, oldShape] = getSizeClassToParams(note.sizeClass);
      const [size, shape] = getSizeClassToParams(update.sizeClass);
      if (oldSize !== size) editedValue.push('size');
      if (oldShape !== shape) editedValue.push('aspectRatio');
    }

    if (update.text) {
      editedValue.push('text');
    }

    aggregatedEditedValues[note.id].push(...editedValue);
  });

  Object.keys(aggregatedEditedValues).forEach(key => {
    const details = scene.getNoteElement(key);
    trackEvent('note_edit', {
      note_view: 'board',
      note_edit_type: aggregatedEditedValues[key].join(','),
      ...details
    });
  });
};

/* Group tracking events */

export const trackGroupCreated = (
  noteCount: number,
  method: TrackGroupCreateMethod
) => {
  trackEvent('group_create', {
    note_count: noteCount,
    method
  });
};

export const trackGroupNoteAdded = (
  noteCount: number,
  groupType: 'manual' | 'theme_auto'
) => {
  trackEvent('group_add_note', {
    note_count: noteCount,
    group_type: groupType
  });
};

export const trackGroupNoteRemoved = (
  notes: NoteElement[],
  scene: Scene,
  groupType: 'manual' | 'theme_auto'
) => {
  const newNoteCount: Record<string, number> = {};

  notes.forEach(note => {
    if (note.placement.parentId) {
      const group = scene.getGroupElement(note.placement.parentId);
      if (!newNoteCount[note.placement.parentId])
        newNoteCount[note.placement.parentId] = group?.notes.length || 0;
      newNoteCount[note.placement.parentId]--;
    }
  });

  Object.values(newNoteCount).forEach(count => {
    trackEvent('group_remove_note', {
      note_count: count,
      group_type: groupType
    });
  });
};

export const trackGroupEdited = (
  noteCount: number,
  editType: 'resize' | 'rename' | 'align'
) => {
  trackEvent('group_edit', {
    note_count: noteCount,
    group_edit_type: editType
  });
};

export const trackGroupUngrouped = (
  noteCount: number,
  groupType: 'manual' | 'theme_auto'
) => {
  trackEvent('group_ungroup', {
    note_count: noteCount,
    group_type: groupType
  });
};

export const trackGroupDeleted = (
  noteCount: number,
  groupType: 'manual' | 'theme_auto',
  method: 'menu' | 'key_cmd'
) => {
  trackEvent('group_delete', {
    note_count: noteCount,
    group_type: groupType,
    method
  });
};

export const trackGroupSorted = ({
  groupCount,
  noteCount,
  ungroupedNoteCount,
  method
}: {
  groupCount: number;
  noteCount: number;
  ungroupedNoteCount: number;
  method: 'complete_board' | 'multi_select';
}) =>
  trackEvent('group_create_sorted', {
    sorted_group_count: groupCount,
    grouped_note_count: noteCount,
    ungrouped_note_count: ungroupedNoteCount,
    method
  });

/* Stack events */

export const trackStackCreated = (
  noteCount: number,
  method: TrackStackCreateMethod
) => {
  trackEvent('stack_create', {
    method,
    note_count: noteCount
  });
};

export const trackStackDeleted = (noteCount: number) => {
  trackEvent('stack_delete', {
    note_count: noteCount
  });
};

export const trackStackScrolled = (noteCount: number) => {
  trackEvent('stack_scroll', {
    note_count: noteCount
  });
};

/* Voting tracking events */

export const trackVoteBegun = (userCount: number) => {
  trackEvent('vote_begin', { user_count: userCount });
};

export const trackVoteReset = (voteCount: number) => {
  trackEvent('vote_reset', { vote_count: voteCount });
};

export const trackVoteAdded = (voteCount: number) => {
  trackEvent('vote_add', { vote_count: voteCount });
};

export const trackVoteRemoved = (voteCount: number) => {
  trackEvent('vote_remove', { vote_count: voteCount });
};

export const trackVotePanelOpened = () => {
  trackEvent('vote_panel_open', {});
};

export const trackVotePanelClosed = () => {
  trackEvent('vote_panel_close', {});
};

export const trackVotingCompleted = (voteCount: number, userCount: number) => {
  trackEvent('vote_complete', { vote_count: voteCount, user_count: userCount });
};

/* Explore topics events */

export const trackExploreTopics = (
  bingCount: number,
  wikiCount: number,
  fileCount: number,
  peopleCount: number
) => {
  trackEvent('group_topic_explore', {
    bing_result_count: bingCount,
    wiki_result_count: wikiCount,
    file_result_count: fileCount,
    people_result_count: peopleCount
  });
};

export const trackExplorePinItem = (
  type?: 'bing' | 'wiki' | 'people' | 'file'
) => {
  trackEvent('explore_item_pin', {
    item_type: type
  });
};

export const trackExploreUnpinItem = (
  type?: 'bing' | 'wiki' | 'people' | 'file',
  method?: 'pin_panel' | 'explore_panel'
) => {
  trackEvent('explore_item_unpin', {
    item_type: type,
    method
  });
};

export const trackExplorePanelToggle = (isOpen: boolean) => {
  if (isOpen) trackEvent('pin_panel_open', {});
  else trackEvent('pin_panel_close', {});
};

export const trackExploreItemOpen = (
  type: 'bing' | 'wiki' | 'people' | 'file',
  method?: 'pin_panel' | 'explore_panel'
) => {
  trackEvent('explore_item_open', {
    item_type: type,
    method
  });
};

/* Task export events */

export const trackTaskExportBegin = (
  taskCount: number,
  method: TaskExportMethod
) => {
  trackEvent('task_export_begin', {
    task_count: taskCount,
    method
  });
};

export const trackTaskRemove = (
  taskName: string,
  assignedTask: 'yes' | 'no',
  dueDate: string,
  bucketType: string,
  method?: TaskExportMethod
) => {
  trackEvent('task_remove', {
    task_name: taskName,
    assigned_task: assignedTask,
    due_date: dueDate,
    bucket_type: bucketType,
    method
  });
};

export const trackTaskExportComplete = (
  groupType: 'existing_group' | 'new_group',
  taskCount: number,
  assignedTaskCount: number,
  dueDateCount: number,
  bucketTypeCount: string,
  method?: TaskExportMethod
) => {
  trackEvent('task_export_complete', {
    group_type: groupType,
    task_count: taskCount,
    assigned_task_count: assignedTaskCount,
    due_date_count: dueDateCount,
    bucket_type_count: bucketTypeCount,
    method
  });
};

export const trackTaskExportDismiss = (
  groupType: 'existing_group' | 'new_group',
  taskCount: number,
  assignedTaskCount: number,
  dueDateCount: number,
  bucketTypeCount: string,
  method?: TaskExportMethod
) => {
  trackEvent('task_export_dismiss', {
    group_type: groupType,
    task_count: taskCount,
    assigned_task_count: assignedTaskCount,
    due_date_count: dueDateCount,
    bucket_type_count: bucketTypeCount,
    method
  });
};

/* Login events */

export const trackLoginBegin = () => {
  trackEvent('login_begin', {});
};

export const trackLoginComplete = () => {
  trackEvent('login_complete', {});
};

/* Search events */

export const trackSearchBegin = () => {
  trackEvent('search_begin', {});
};

export const trackSearchComplete = ({
  method,
  noteCount,
  keywordCount
}: {
  method: TrackSearchCompleteMethod;
  noteCount: number;
  keywordCount: number;
}) => {
  trackEvent('search_complete', {
    method,
    note_count: noteCount,
    keyword_count: keywordCount
  });
  if (method === 'group') trackGroupCreated(noteCount, 'search');
  if (method === 'stack') trackStackCreated(noteCount, 'search');
};

export const trackSearchDismiss = ({
  noteCount,
  keywordCount
}: {
  noteCount: number;
  keywordCount: number;
}) => {
  trackEvent('search_dismiss', {
    note_count: noteCount,
    keyword_count: keywordCount
  });
};
