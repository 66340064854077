import { Box, Loader } from '@fluentui/react-northstar';
import * as microsoftTeams from '@microsoft/teams-js';
import React from 'react';
import { useEffectOnce } from 'react-use';

import { authenticateWithRedirect } from '../../../../src/services/auth';

import '../auth-start/Auth.scss';

const AuthUpdate = () => {
  window.focus();
  useEffectOnce(() => {
    microsoftTeams.initialize(() => {
      microsoftTeams.getContext(context => {
        authenticateWithRedirect(context.loginHint);
      });
    });
  });

  return (
    <Box className="auth-teams">
      <Loader label="Connecting to the identity provider..." />
    </Box>
  );
};

// eslint-disable-next-line import/no-default-export
export default AuthUpdate;
