import npmAxios, { AxiosError, AxiosInstance } from 'axios';
import { QueryClient } from 'react-query';

import {
  authenticateWithRedirect,
  refreshTokenIfNeeded
} from '../services/auth';
import { getDeviceId, useAuthStore } from '../stores/AuthStore/useAuthStore';
import { Token } from '../types/auth';
import { REST_URL, API_KEY, REST_API_VERSION } from '../utils/constants';

const retryIfNot404 = (failureCount: number, error: unknown) =>
  (error as AxiosError).response?.status !== 404 && failureCount < 3;

export const applyDefaultHeaders = (headers: Record<string, string> = {}) => {
  const deviceId = getDeviceId();
  return Object.assign(headers, {
    'Device-ID': deviceId,
    'Api-Key': API_KEY,
    'Api-Version': REST_API_VERSION
  });
};

export const axios = npmAxios.create({
  headers: applyDefaultHeaders()
});

export const authenticatedAxios = (() => {
  let refreshingToken: Promise<Token> | null;
  let instance: AxiosInstance;

  return async () => {
    let { token } = useAuthStore.getState();
    if (!token) throw new Error('Missing auth token');

    // Queue up while we are refreshing
    // Otherwise refresh token if needed
    // Also reset refreshingToken on error,
    // so we don't wait for failed refreshingToken which can cause login loops
    try {
      if (refreshingToken) {
        token = await refreshingToken;
      } else {
        refreshingToken = refreshTokenIfNeeded(token);
        token = await refreshingToken;
        refreshingToken = null;
      }
    } catch (error) {
      refreshingToken = null;
      throw error;
    }

    if (!instance) {
      instance = npmAxios.create({
        baseURL: REST_URL,
        headers: applyDefaultHeaders({
          Authorization: `Bearer ${token.access_token}`
        })
      });

      instance.interceptors.response.use(
        response => response,
        error => {
          if (error?.response?.data?.code === 'ApiVersionTooOld')
            useAuthStore.setState({ apiVersionTooOld: true });
          // FIXME: Any 401 triggers authentication for now
          if (error?.response?.status === 401) authenticateWithRedirect();
        }
      );
    } else {
      // Axios does not type the headers update yet
      (
        instance.defaults.headers as any
      ).Authorization = `Bearer ${token.access_token}`;
    }

    return instance;
  };
})();

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: retryIfNot404,
      refetchOnWindowFocus: false
    }
  }
});
