import * as microsoftTeams from '@microsoft/teams-js';
import { useState } from 'react';
import { useEffectOnce } from 'react-use';

export const useGetTeamsContext = () => {
  const [context, setContext] = useState<microsoftTeams.Context | undefined>();

  useEffectOnce(() => {
    microsoftTeams.initialize(() => {
      microsoftTeams.getContext(async c => {
        setContext(c);
      });
    });
  });

  return context;
};
