/* eslint-disable no-template-curly-in-string */
import { array, boolean, InferType, mixed, number, object, string } from 'yup';

import { cast } from '../apis/utils.schema';
import { FractionalIndex, Metadata } from '../utils/core';
import { VoteAlt } from './core';

export const pointSchema = object({
  x: number().required(),
  y: number().required()
}).required();

export const sizeSchema = object({
  width: number().required(),
  height: number().required()
}).required();

export const SIZE_CLASSES = {
  '3x3': '3x3',
  '5x5': '5x5',
  '7x7': '7x7',
  '3x5': '3x5',
  '5x7': '5x7',
  '7x10': '7x10',
  '5x3': '5x3',
  '7x5': '7x5',
  '10x7': '10x7'
} as const;
export const sizeClassSchema = mixed<`${string}x${string}`>()
  .test({
    name: 'sizeClass',
    message: '${path} must be a type of `${string}x${string}`',
    test: value => !!value && value.split('x').every(v => !Number.isNaN(+v))
  })
  .required();
export type SizeClass = InferType<typeof sizeClassSchema>;

const sizeClassObjectSchema = object({
  constant: sizeClassSchema,
  noteSize: sizeSchema
}).required();
export type SizeClassObject = InferType<typeof sizeClassObjectSchema>;

const ocrSchema = object({
  detectedText: string().nullable()
});

const fractionalIndexSchema = mixed<FractionalIndex>()
  .test({
    name: 'fractionalIndex',
    message: '${path} must be an instance of "FractionalIndex"',
    test: value => value instanceof FractionalIndex
  })
  .required();

const layerSchema = object({
  version: string().required(),

  // The blurhash that we don't use
  thumbnail: string().nullable(),

  // The equivalent of the sizeClass but in mm
  contentSize: object({
    width: number().required(),
    height: number().required()
  }).required(),

  // The pixel dimension of the image saved
  pixelSize: object({
    width: number().required(),
    height: number().required()
  }).required()
});

export const NOTE_TYPEFACES = {
  'roboto-slab': 'roboto-slab',
  kalam: 'kalam',
  'permanent-marker': 'permanent-marker',
  'noto-sans': 'noto-sans'
} as const;
export const noteTypefaceSchema = mixed<Values<typeof NOTE_TYPEFACES>>()
  .test({
    name: 'noteTypeface',
    message: '${path} must be a value of `NOTE_TYPEFACES` but was ${value}',
    test: value =>
      !value || Object.values(NOTE_TYPEFACES).some(v => v === value)
  })
  .default(NOTE_TYPEFACES.kalam);
export type NoteTypeface = InferType<typeof noteTypefaceSchema>;

export const NOTE_ALIGNMENTS = {
  left: 'left',
  right: 'right',
  center: 'center'
} as const;
export const noteAlignmentSchema = mixed<Values<typeof NOTE_ALIGNMENTS>>()
  .test({
    name: 'noteAlignment',
    message: '${path} must be a value of `NOTE_ALIGNMENTS`',
    test: value =>
      !!value && Object.values(NOTE_ALIGNMENTS).some(v => v === value)
  })
  .default(NOTE_ALIGNMENTS.center);
export type NoteAlignment = InferType<typeof noteAlignmentSchema>;

export const textContentsObjectSchema = object({
  contents: string().ensure(),
  displayString: string().ensure(),
  typeface: object({
    name: noteTypefaceSchema
  })
}).required();

export const textSchema = object({
  contents: string().ensure(),
  displayString: string().nullable(),
  contentSize: sizeClassObjectSchema,
  typeface: noteTypefaceSchema
}).transform(v => {
  if (v?.contents && typeof v.contents === 'object') {
    const contents = cast(textContentsObjectSchema, v.contents);
    Object.assign(v, {
      contents: contents.contents,
      displayString: contents.displayString,
      typeface: contents.typeface.name
    });
  }
  return v;
});

export const voteSchema = object({
  type: mixed<'vote'>().default('vote').required(),
  noteId: string().required(),
  userId: string().required(),
  voteId: string().required(),
  vote: object({
    location: pointSchema.nullable().notRequired(),
    timestamp: number().required()
  }).required()
});
export type Vote = InferType<typeof voteSchema>;

export const NOTE_LISTS = {
  bullet: 'bullet',
  dash: 'dash',
  number: 'number',
  none: 'none'
} as const;
export const noteListSchema = mixed<Values<typeof NOTE_LISTS>>()
  .test({
    name: 'noteList',
    message: '${path} must be a value of `NOTE_LISTS`',
    test: value => !!value && Object.values(NOTE_LISTS).some(v => v === value)
  })
  .default(NOTE_LISTS.none);
export type NoteList = InferType<typeof noteListSchema>;

export type NoteTextFormat = {
  typeface: NoteTypeface;
  alignment: NoteAlignment;
  listType: NoteList;
};

export const placementSchema = object({
  center: pointSchema,
  zIndex: fractionalIndexSchema,
  parentId: string().nullable()
}).required();

export type NotePlacement = InferType<typeof placementSchema>;
export type GroupPlacement = InferType<typeof placementSchema>;

export const noteSchema = object({
  type: mixed<'note'>().default('note').required(),
  id: string().required(),
  rotation: number().required(),
  sizeClass: sizeClassObjectSchema,
  text: textSchema.nullable(),
  baseLayer: layerSchema.nullable(),
  drawLayer: layerSchema.nullable(),
  placement: placementSchema,
  color: string().required(),
  deleted: boolean().required(),
  ocr: ocrSchema.nullable(),
  votes: array(voteSchema).required(),
  uploadedBaseLayers: array(string().required()),
  uploadedDrawLayers: array(string().required()),
  alignment: noteAlignmentSchema,
  owner: string().nullable()
})
  .required()
  .transform(
    (
      note:
        | Partial<Omit<Note, 'votes'> & { votes?: VoteAlt | Vote[] }>
        | undefined
    ) => {
      !note ||
        Array.isArray(note.votes) ||
        (note.votes = Object.entries(note.votes ?? []).reduce(
          (p, [userId, v]) => {
            Object.entries(v).forEach(([voteId, vote]) => {
              p.push(
                cast(voteSchema, {
                  voteId,
                  userId,
                  noteId: note.id,
                  vote
                })
              );
            });
            return p;
          },
          [] as Vote[]
        ));

      return note;
    }
  );
export type Note = InferType<typeof noteSchema>;

export const metadataRecordSchema = mixed<Record<string, Metadata>>()
  .test({
    name: 'metadata',
    message: '${path} properties must be an instance of "Metadata"',
    test: metadata =>
      !metadata ||
      Object.values(metadata).every(data => data instanceof Metadata)
  })
  .required();
export type MetadataRecord = InferType<typeof metadataRecordSchema>;

export const groupSchema = object({
  id: string().required(),
  metadata: metadataRecordSchema,
  title: string().nullable(),
  placement: placementSchema,
  size: sizeSchema,
  deleted: boolean().required(),
  lock: object({}).nullable(),
  stacked: boolean().required(),
  owner: string().nullable()
}).required();
export type Group = InferType<typeof groupSchema>;

export const boardSchema = object({
  id: string().required(),
  groups: array(groupSchema).required(),
  notes: array(noteSchema).required(),
  metadata: metadataRecordSchema,
  uploadedBaseLayers: array(string().required()).ensure(),
  uploadedDrawLayers: array(string().required()).ensure()
}).required();
export type Board = InferType<typeof boardSchema>;
